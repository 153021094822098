import React, { useRef, useState } from "react";
import { useParams } from "react-router-dom";
import QR_Bell from "../qrTypes/QR_Bell";
import { qrBaseUrl } from "../constants";
import "../Globals/qr.css";
import * as htmlToImage from "html-to-image";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { Download } from "@mui/icons-material";

const createFileName = (extension = "", ...names: any[]) => {
  if (!extension) {
    return "";
  }
  return `${names.join("")}.${extension}`;
};

const QrCode = () => {
  const [isShowButton, setIsShowButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const { qrId } = useParams();
  const ref: any = useRef(null);

  const takeScreenShot = async (node: any) => {
    const dataURI = await htmlToImage.toJpeg(node);
    return dataURI;
  };

  const download = (
    image: string,
    { name = "QrCode", extension = "jpg" } = {}
  ) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
    setLoading(false);
  };

  const downloadScreenshot = () => {
    setLoading(true);
    console.log("called");
    takeScreenShot(ref.current).then(download);
  };
  const openHTMLAsPDF = () => {
    setIsShowButton(false);
    setTimeout(() => {
      window.print();
    }, 100);
    setTimeout(() => {
      setIsShowButton(true);
    }, 200);
  };
  return (
    <div className="h-screen flex flex-col justify-center items-center w-screen">
      <div ref={ref} className="flex justify-center items-center rounded-md">
        <QR_Bell value={`${qrBaseUrl}/?vm=${qrId}`} />
      </div>
      {isShowButton && (
        <div className="flex justify-center items-center gap-3">
          <ButtonWithIcon
            buttonLoading={loading}
            width={"200px"}
            onClick={() => {
              downloadScreenshot();
            }}
            iconName={loading ? "" : <Download />}
            buttonName={"Download Image"}
            background={"green"}
          />

          <ButtonWithIcon
            buttonLoading={false}
            width={"200px"}
            onClick={() => {
              openHTMLAsPDF();
            }}
            iconName={<Download />}
            buttonName={"Download PDF"}
          />
        </div>
      )}
    </div>
  );
};

export default QrCode;
