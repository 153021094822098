import { Component } from "react";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { Add, Delete, Edit, Key } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CustomAlert from "../../../components/CustomAlert";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import { LockManagerComponentWrap } from "./LockManagerComponent";
import AddLock from "../../../components/AddLock";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { StyledDataGrid } from "../apartments/AparmetsView";

const label = { inputProps: { "aria-label": "Switch demo" } };

interface Props {
  controller: LockManagerComponentWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
export class LockManagerView extends Component<Props> {
  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType }: any = this.props.controller.context;
    const columns: GridColDef[] = [
      {
        field: "name",
        headerName: "Name",
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "lockId",
        headerName: "Device Id",
        width: 100,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "cloudNode",
        headerName: "Integration Key",
        width: 180,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "edit",
        headerName: "Edit",
        width: 100,
        renderCell: (index) => {
          return index.api.getRow(index.row.id)?.aptOrSubDivision ==
            index.api.getRow(index.row.id)?.lock?.aptOrSubDivision ? (
            <div
              className="flex-row justify-center gap-5 cursor-pointer"
              onClick={() => {
                console.log(index.api.getRow(index.row.id));
                this.props.controller.onShowEditApartmentWithValue(
                  index.api.getRow(index.row.id)
                );
              }}
            >
              <Edit />
            </div>
          ) : (
            <Edit color={"disabled"} />
          );
        },
      },

      {
        field: "delete",
        headerName: "Delete",
        width: 100,
        headerAlign: "center",
        align: "center",
        renderCell: (index) => {
          return index.api.getRow(index.row.id)?.aptOrSubDivision ==
            index.api.getRow(index.row.id)?.lock?.aptOrSubDivision ? (
            <div className="flex-row justify-center gap-5 cursor-pointer">
              <CustomAlert
                onPressDelete={() => {
                  // console.log(index.api.getRow(index.row.id));
                  controller.deleteLock(
                    index.api.getRow(index.row.id)?.lock?._id
                  );
                  return;
                }}
              />
            </div>
          ) : (
            <Delete color={"disabled"} />
          );
        },
      },
      {
        field: "managelock",
        headerName: "Lock Status",
        width: 200,
        headerAlign: "center",
        align: "center",
        renderCell: (index) => {
          console.log(index.api.getRow(index.row.id));
          return true ? (
            this.props.controller.state.swithcButtonLoadingId !=
            index.api.getRow(index.row.id)._id ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* <h1 style={{ color: "red" }}>
                  {index.api.getRow(index.row.id).isActive.toString()}
                </h1> */}
                <p>Unlock</p>
                <Switch
                  {...label}
                  value={index.api.getRow(index.row.id).isActive}
                  defaultChecked={index.api.getRow(index.row.id).isActive}
                  checked={index.api.getRow(index.row.id).isActive}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    index.api.getRow(index.row.id)?.aptOrSubDivision ==
                    index.api.getRow(index.row.id)?.lock?.aptOrSubDivision
                      ? controller.onToggleLockStatus(
                          index.api.getRow(index.row.id)?.lock?._id,
                          event.target.checked,
                          index.api.getRow(index.row.id)._id
                        )
                      : controller.onToggleLockStatusByPropertyId(
                          index.api.getRow(index.row.id)._id,
                          event.target.checked,
                          index.api.getRow(index.row.id)._id
                        );
                  }}
                />
                <p>Lock</p>
              </div>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size={20} />
              </Box>
            )
          ) : (
            <Switch
              disabled
              defaultChecked={index.api.getRow(index.row.id).isActive}
            />
          );
        },
      },
    ];
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">
        {controller.state.isShowAddLock && (
          <AddLock
            controller={controller}
            onClose={controller.onShowAddLock}
            onSubmit={controller.addLock}
            buttonLoading={
              controller.state.loading == loadingEnu.EDITLOADING ||
              controller.state.loading == loadingEnu.ADDBUTTONLOADING
                ? true
                : false
            }
            textFields={[]}
            isAddBox={true}
          />
        )}
        {controller.state.isShowEditLock && (
          <AddLock
            controller={controller}
            onClose={controller.onShowEditLock}
            onSubmit={controller.editAparment}
            isAddBox={false}
            buttonLoading={
              controller.state.loading == loadingEnu.EDITLOADING ||
              controller.state.loading == loadingEnu.ADDBUTTONLOADING
                ? true
                : false
            }
            textFields={[]}
          />
        )}
        <div className="flex gap-10 w-60">
          <ButtonWithIcon
            buttonName={"Add Lock"}
            iconName={<Add />}
            onClick={controller.onShowAddLock}
            width={"130px"}
          />
        </div>
        {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
          <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
            <LinearIndeterminate />
            <StyledDataGrid
              columns={columns}
              hideFooterPagination={true}
              style={{ background: "#fff", height: "90%" }}
            />
          </div>
        ) : (
          <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
            <StyledDataGrid
              rows={controller.state.locks.map((row: any, index) => ({
                ...row,
                id: index + 1,
                lockId: row.lock.device.deviceId,
                cloudNode: row.lock.device.cloudNode,
                name: row.lock.name,
                Key: index,
              }))}
              columns={columns}
              style={{ background: "#fff", height: "100%" }}
              // hideFooterPagination={true}
              autoPageSize={true}
                  pagination={true}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
            />
          </div>
        )}
      </div>
    );
  }
}

export default LockManagerView;
