import React, { MouseEventHandler, useState } from "react";
import TextFieldSimple from "../Neet-Components/Text-Fields/TextFieldSimple";
import { Clear, Map } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { AparmetsComponentWrap } from "../pages/dashboard/apartments/AparmetsComponent";
import FormControl from "@mui/material/FormControl";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { BuildingTypes } from "../data/BuildingTypes";
import ImageUpload from "./ImageUpload";
import CustomAlert from "./CustomAlert";

interface Props {
  textFields: Array<{}>;
  onSubmit: Function;
  buttonLoading: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  controller: AparmetsComponentWrap;
  isAddApartment: boolean;
}
interface State {
  isAllValueAreValid: string;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
const AddApartments = (props: Props) => {
  const { currentColor, currentMode } = useStateContext();
  const [base64, setBase64]: any = useState();
  const [isAllValueAreValid, setIsAllValueAreValid] = useState("none");
  return (
    <div className="fixed h-screen w-screen left-0 top-0 bg-[#0000007b] z-10 flex items-center justify-center">
      <div className="bg-white  flex flex-wrap items-center justify-center gap-2 w-[500px] p-10 pt-20 relative rounded-xl">
        <div className=" absolute top-[20px] w-full text-center">
          <p className="text-3xl font-semibold text-neutral-700">
            Add Building
          </p>
        </div>
        <div
          onClick={props.onClose}
          className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer"
          style={{
            background: currentColor,
            boxShadow:
              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;",
          }}
        >
          <Clear sx={{ color: "white" }} />
        </div>

        <FormControl sx={{ m: 1, minWidth: "90%" }} size="small">
          <InputLabel id="demo-select-small-label">Select Unit Type</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={props.controller.state.apartmentType}
            label="Select Bulding Type"
            onChange={props.controller.handleChange}
          >
            {BuildingTypes.map((_item: any) => (
              <MenuItem value={_item}>{_item.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextFieldSimple
          errorAbsolute={true}
          onChange={
            props.isAddApartment
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder="Name*"
          defaultValue={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.name
              : props.controller.state.apartmentToEdit.name
          }
          value={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.name
              : props.controller.state.apartmentToEdit.name
          }
          errorMsg={isAllValueAreValid == "name" ? "Enter a valid name" : ""}
          width="200px"
          name="name"
        />

        <TextFieldSimple
          errorAbsolute={true}
          onChange={
            props.isAddApartment
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder="Address*"
          defaultValue={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.address
              : props.controller.state.apartmentToEdit.address
          }
          value={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.address
              : props.controller.state.apartmentToEdit.address
          }
          errorMsg={
            isAllValueAreValid == "address" ? "Enter a valid Address" : ""
          }
          width="200px"
          name="address"
        />

        <TextFieldSimple
          errorAbsolute={true}
          onChange={
            props.isAddApartment
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder="Phone Number"
          defaultValue={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.phoneNumber
              : props.controller.state.apartmentToEdit.phoneNumber
          }
          value={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.phoneNumber
              : props.controller.state.apartmentToEdit.phoneNumber
          }
          errorMsg={
            isAllValueAreValid == "phoneNumber"
              ? "Enter a valid phone number"
              : ""
          }
          width="200px"
          name="phoneNumber"
        />
        <TextFieldSimple
          errorAbsolute={true}
          placeholder="Location*"
          onChange={
            props.isAddApartment
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          defaultValue={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.location
              : props.controller.state.apartmentToEdit.location
          }
          value={
            props.isAddApartment
              ? props.controller.state.apartmentToAdd.location
              : props.controller.state.apartmentToEdit.location
          }
          errorMsg={
            isAllValueAreValid == "location" ? "Enter a valid location" : ""
          }
          width="200px"
          name="location"
        />
        <div className="absolute right-10 w-52 bottom-[22%]">
          <ButtonWithIcon
            buttonName={"Address On Map"}
            iconName={<Map />}
            onClick={() => {
              props.controller.onShowMapButtonPressed();
            }}
          />
        </div>
        {props.controller.state.editHasLogo && (
          <div className="absolute left-10 w-52 bottom-[30%]">
            <CustomAlert
              loading={props.controller.state.loading == loadingEnu.REMOVELOGO}
              text={"Remove Logo"}
              msg={"Do you want to remove logo?"}
              background={"red"}
              color={"#fff"}
              width={"95%"}
              onPressDelete={() => {
                props.controller.removeLogo(
                  props.controller.state.apartmentToEdit._id
                );
              }}
            />
          </div>
        )}
        <div className="w-full mt-6">
          <ImageUpload
            setLogoBase64={setBase64}
            controller={props.controller}
            isAddApartment={props.isAddApartment}
          />
        </div>
        <div className="mb-2 w-full h-1"></div>

        {props.isAddApartment ?<ButtonWithIcon
            buttonName={"Save"}
            onClick={async () => {
              const res = await props.onSubmit();
              console.log(res);
              if (res) {
                setIsAllValueAreValid(res);
              }
            }}
            buttonLoading={props.buttonLoading}
          />:<div className="w-full flex items-center justify-center gap-3 flex-row">
          <CustomAlert
            onPressDelete={() => {              
              props.controller.deleteApartment(
                props.controller.state.apartmentToEdit._id
              );
            }}
            loading={props.controller.state.loading==loadingEnu.DELETELOADING}
            color={"red"}
            // text={"Delete Apartment"}
          />
          <ButtonWithIcon
            buttonName={"Save"}
            onClick={async () => {
              const res = await props.onSubmit();
              console.log(res);
              if (res) {
                setIsAllValueAreValid(res);
              }
            }}
            buttonLoading={props.buttonLoading}
          />
        </div>}
        
      </div>
    </div>
  );
};

export default AddApartments;
