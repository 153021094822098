import ApiClient from "../../../Api/apiClient";

export const getLocksApi = async (
  token: string,
  apartmentOrSubdivisionId: string
) => {
  return await ApiClient.getInstance().get(
    `/lockProperties/admin/lockForProperty/${apartmentOrSubdivisionId}`,
    token
  );
};
export const deleteLockByIdApi = async (id: string, token: string) => {
  return await ApiClient.getInstance().delete(`/locks/deleteById/${id}`, token);
};
export const changeLockStatusByIdApi = async (
  id: string,
  isActive: boolean
) => {
  const status = isActive ? 1 : 0;
  return await ApiClient.getInstance().get(
    `locks/updateLockActiveStatus/${id}/newStatus/${status}`
  );
};
export const changeLockStatusByPropertyApi = async (
  id: string,
  isActive: boolean
) => {
  const status = isActive ? 1 : 0;
  return await ApiClient.getInstance().get(
    `/lockProperties/updatePropertyActiveStatus/${id}/newStatus/${status}`
  );
};
export const getCloudNodeByCredentialsApi = async (credentialId: string) => {
  return await ApiClient.getInstance().get(
    `/pdkLock/cloudNodesForCredential/${credentialId}`
  );
};
export const getDevicesByCredentialsApi = async (
  credentialId: string,
  cloudNodeId: string
) => {
  return await ApiClient.getInstance().get(
    `/pdkLock/devicesForCredential/${credentialId}/andCloudNode/${cloudNodeId}`
  );
};
export const addLockApi = async (
  lock: {
    name: string;
    lockCredentials: string;
    aptOrSubDivision: string;
    deviceId: string;
    createdBy: string;
    _id?: string;
    cloudNode: string;
  },
  token: string
) => {
  return await ApiClient.getInstance().post("/locks/addEditLock", lock, token);
};
