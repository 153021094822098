import { cookiesSaved } from "../constants";

export const validateObject = (
  obj: any,
  notToValidate: Array<string> | undefined
) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      if (notToValidate) {
        if (notToValidate.includes(key)) {
          continue;
        }
      }
      const value = obj[key];
      if (key === "email" || key == "username") {
        const validEmail = validateEmail(value);
        if (!validEmail) {
          return key;
        }
      }
      if (typeof value === "string" && value.trim() === "") {
        return key;
      }
      if (typeof value === "number" && isNaN(value)) {
        return key;
      }
    }
  }
  return "right"; // Return "right" if all keys (not in notToValidate) have valid values
};

export const LogOutAdmin = (navigation: any, setIsUserLogedIn: Function) => {
  setIsUserLogedIn(false);
  navigation.navigate("/");
};
export const validateEmail = (email: string) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};

export const setDataToLocalStorageByKeyName = (
  keyName: string,
  localData: any
) => {
  localStorage.setItem(keyName, JSON.stringify(localData));
  return true;
};

export const getDetailsFromLocal = (keyName: string) => {
  const retrieveData: any = localStorage.getItem(keyName);
  if (retrieveData) {
    const adminData = JSON.parse(retrieveData);
    if (adminData) {
      return adminData;
    } else {
      return undefined;
    }
  } else {
    return undefined;
  }
};

const getCookie = (n: any) => {
  let a = `; ${document.cookie}`.match(`;\\s*${n}=([^;]+)`);
  return a ? a[1] : "";
};

export const getUserToken = (): string | undefined => {
  const token = getCookie(cookiesSaved.auth_Token);
  if (token) {
    return token;
  } else {
    return undefined;
  }
};

export const isValidPassword = (password: string) => {
  // Define regex patterns for each requirement
  const lengthPattern = /.{8,}/;
  const uppercasePattern = /[A-Z]/;
  const lowercasePattern = /[a-z]/;
  const numberPattern = /\d/;
  // const symbolPattern = /[^A-Za-z0-9]/;

  // Check if the password matches all the patterns
  const hasValidLength = lengthPattern.test(password);
  const hasUppercase = uppercasePattern.test(password);
  const hasLowercase = lowercasePattern.test(password);
  const hasNumber = numberPattern.test(password);
  // const hasSymbol = symbolPattern.test(password);

  // Return true if all conditions are met
  return hasValidLength && hasUppercase && hasLowercase && hasNumber;
};
