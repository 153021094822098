import React from "react";
import { useStateContext } from "../context/ContextProvider";
import { Clear } from "@mui/icons-material";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

interface Props {
  onCopyButtonPressed: Function;
  onClose:Function;
  loading:boolean;
}
const GenerateUserLink = ({ onCopyButtonPressed ,onClose,loading}: Props) => {
  const { currentColor } = useStateContext();
  return (
    <div className="w-screen h-screen fixed flex items-center justify-center left-0 top-0 bg-[#0000007b] z-10">
      <div className="relative p-2 bg-white rounded-md h-[200px] w=[500px] flex flex-col items-center gap-[34px]">
        <div
          onClick={() => {onClose()}}
          className="rounded-full w-10 h-10 z-50 flex items-center justify-center absolute right-[-6px] top-[-6px] cursor-pointer shadow-black shadow-md"
          style={{ background: currentColor }}
        >
          <Clear sx={{ color: "white" }} />
        </div>
        <p className="text-2xl font-semibold text-neutral-700">
          Copy And Share
        </p>
        <div className="flex items-center justify-between flex-col gap-[20px]">
          <p className="w-[70%] text-center">
            Copy Link And Share With User To Add Directly.
          </p>
          <ButtonWithIcon
            width={"250px"}
            buttonName={""}
            background={currentColor}
            onClick={() => {
              onCopyButtonPressed();
            }}
            buttonLoading={loading}
            iconName={
              <ContentCopyIcon sx={{ height: "25px", color: "#fff" }} />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default GenerateUserLink;
