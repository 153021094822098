import { Component } from "react";
import {GridColDef } from "@mui/x-data-grid";
import moment from "moment";
// import moment from "moment-timezone";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import { CallLogsWrap } from "./CallLogsComponent";
import TablePaginationMui from "../../../components/TablePagination";
import SelectApartmentOneOfAll from "../../../components/SelectApartmentOneOfAll";
import style from "../apartments/settings.module.css";
import { logsImageBaseUrl } from "../../../constants";
import { Clear } from "@mui/icons-material";
import { StyledDataGrid } from "../apartments/AparmetsView";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { TbReload } from "react-icons/tb";

interface Props {
  controller: CallLogsWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class CallLogsView extends Component<Props> {


  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType, currentColor }: any =
      this.props.controller.context;
    const columns: GridColDef[] = [
      {
        field: "picture",
        headerName: "Visitor Image",
        width: 120,
        headerAlign: "center",
        renderCell: (index) => {
          return index.api.getRow(index.row.id)?.picture ? (
            <div
              className={style.logo_container}
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.controller.setState({
                  showImage: true,
                  imageUrl:
                    logsImageBaseUrl + index.api.getRow(index.row.id).picture,
                });
              }}
            >
              <img
                src={logsImageBaseUrl + index.api.getRow(index.row.id).picture}
              />
            </div>
          ) : (
            <div className={style.logo_container}>
              <p>--</p>
            </div>
          );
        },
      },
      {
        field: "name",
        headerName: "Visitor Details",
        width: 300,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "purpose",
        headerName: "Purpose Of Visit",
        width: 300,
        headerAlign: "center",
        align: "center",
      },      
      // {
      //   field: "phone",
      //   headerName: "Visitor Phone",
      //   width: 140,
      //   align: "center",
      //   headerAlign: "center",
      // },
      // { field: "room", headerName: "Room No", width: 120 },
      { field: "calldetails", headerName: "Call", width: 100 },
      {
        headerAlign: "center",
        align: "center",
        field: "apartment",
        headerName: "Units",
        width: 150,
      },
      {
        align: "center",
        headerAlign: "center",
        field: "ansby",
        headerName: "Received By",
        width: 120,
      },
      // {
      //   field: "duration",
      //   headerName: "Duration",
      //   width: 120,
      // },
      {
        field: "timestamp",
        headerName: "Date/Time",
        width: 160,
      },
    ];
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">        
        <>
        <div className="rounded-md relative">
        <ButtonWithIcon
        buttonName={"Refresh Page"}
        background={currentColor}
        width={"200px"}
        iconName={<TbReload size={24} />}
        onClick={() => {
          this.props.controller.onPageReload();
        }}
      />
      </div>
          <div className="mt-10 rounded-md overflow-x-scroll h-[70vh] overflow-y-scroll relative">          
            {this.props.controller.state.loading != loadingEnu.PAGELOADING && (
              <div className="flex gap-2 w-60 absolute z-10 right-[10px] -top-2">
                {/* <SelectStatus
                  onStatusChange={this.props.controller.onStatusChange}
                  isShowAll={true}
                /> */}
                <SelectApartmentOneOfAll
                  onStatusChange={this.props.controller.onApartmentStatusChange}
                  apartments={this.props.controller.state.apartments as any}
                  value={this.props.controller.state.selectedApartment}
                />
              </div>
            )}
            {this.props.controller.state.showImage && (
              <div className="w-screen h-screen fixed flex items-center justify-center left-0 top-0 bg-[#0000007b] z-10">
                <div className="relative p-2 bg-white rounded-md">
                  <div
                    onClick={() => {
                      this.props.controller.setState({
                        showImage: false,
                      });
                    }}
                    className="rounded-full w-10 h-10 z-50 flex items-center justify-center absolute right-[-6px] top-[-6px] cursor-pointer shadow-black shadow-md"
                    style={{ background: currentColor }}
                  >
                    <Clear sx={{ color: "white" }} />
                  </div>
                  <img
                    src={this.props.controller.state.imageUrl}
                    alt="logs-img"
                    style={{ width: "500px", height: "auto" }}
                  />
                </div>
              </div>
            )}
            {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
              <div className="h-full">
                <LinearIndeterminate />
                <StyledDataGrid
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "90%" }}
                />
              </div>
            ) : (
              <>
                <StyledDataGrid
                  rows={controller.state.requests.callLogs.map(
                    (row: any, index: any) => ({
                      ...row,
                      id: index + 1,
                      apartment: `${
                        row.subDivision?.parentApartmentSubDivision?.name ?? ""
                      }${
                        row.subDivision?.parentApartmentSubDivision?.name
                          ? "/"
                          : ""
                      }${row.subDivision?.name}`,
                      timestamp: moment(new Date(row.time)).format(
                        " DD-MM-YYYY hh:mm A"
                      ),
                      picture: row.photo??row.visitorId?.photo,
                      ansby: row?.attendedBy?.name ?? "--",
                      calldetails: row?.attendedBy?.name
                        ? "Received"
                        : "Missed call",
                      phone: `${row.visitorId?.phoneNumber ? "+" : ""}${
                        row.visitorId?.phoneNumber ?? "---"
                      }`,
                      name:
                        row.visitorId?.name ||
                        row.visitorId?.phoneNumber ||
                        row.visitorId?.roomNo
                          ? `${row.visitorId?.name ?? ""}${
                              row.visitorId?.phoneNumber ? " , " : ""
                            }${row.visitorId?.phoneNumber ?? ""}${
                              row.visitorId?.roomNo ? " , " : ""
                            }${row.visitorId?.roomNo ?? ""}
                              `
                          : "Unknown Visitor",
                      purpose: row.visitorId?.purposeOfVisit ?? "---",
                    })
                  )}
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "100%" }}
                  // hideFooterPagination={true}
                  hideFooter={true}
                />
              </>
            )}
          </div>
          <div>
            <TablePaginationMui
              pageNo={this.props.controller.state.pageNo}
              pageSize={this.props.controller.state.pageSize}
              onPageNumberChange={this.props.controller.onPageNumberChange}
              onPageSizeChange={this.props.controller.onPageSizeChange}
              totalItemSize={this.props.controller.state.requests.total}
            />
          </div>
        </>
      </div>
    );
  }
}

export default CallLogsView;
