import React, { useEffect, useRef, useState } from "react";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { googleMapsMainApiKey } from "../constants";

interface Props {
  onSubmit: Function;
  onCancel: Function;
}

const SimpleMap = ({ onSubmit, onCancel }: Props) => {
  const mapRef = useRef(null); // Ref for the map element
  const inputRef: any = useRef(null); // Ref for the input element

  useEffect(() => {
    // Load the Google Maps JavaScript API script
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${googleMapsMainApiKey}&libraries=places`;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      initMap(); // Initialize map once script is loaded
    };

    return () => {
      // Clean up: Remove the script when component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const [mapCenter, setMapCenter] = useState({
    lat: 10.99835602,
    lng: 77.01502627,
  });
  const [selectedPlace, setSelectedPlace] = useState<any>(null);

  const handlePlaceSelect = (place: any) => {
    const selectedPlace = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      name: place.formatted_address,
    };
    setMapCenter(selectedPlace);
    setSelectedPlace(selectedPlace);
    if (inputRef.current) {
      inputRef.current.value = place.formatted_address; // Update input field with selected place
    }
  };

  const handleMapClick = (event: any) => {
    const clickedPlace = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
      name: "Selected Location",
    };
    setMapCenter(clickedPlace);
    // Reverse geocode the clicked location to get the address
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: clickedPlace }, (results: any, status) => {
      if (status === "OK" && results[0]) {
        if (inputRef.current) {
          inputRef.current.value = results[0].formatted_address; // Update input field with clicked location
          const updatedClicked = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
            name: results[0].formatted_address,
          };
          setSelectedPlace(updatedClicked);
        }
      }
    });
  };

  const initMap = () => {
    const map = new window.google.maps.Map(mapRef.current as any, {
      center: mapCenter,
      zoom: 13,
      mapTypeControl: false,
    });

    const autocomplete = new window.google.maps.places.Autocomplete(
      inputRef.current as any,
      {
        fields: ["formatted_address", "geometry", "name"],
        strictBounds: false,
      }
    );

    autocomplete.bindTo("bounds", map);

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        window.alert(`No details available for input: '${place.name}'`);
        return;
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
      } else {
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      }

      handlePlaceSelect(place); // Call handlePlaceSelect with the selected place
    });

    // Add click event listener to map
    map.addListener("click", (event: any) => {
      handleMapClick(event); // Call handleMapClick with the clicked coordinates
    });
  };

  return (
    <div className="w-full h-[90vh] rounded">
      <input
        id="pac-input"
        ref={inputRef}
        type="text"
        placeholder="Enter a location"
        style={{
          width: "100%",
          height: "50px",
          fontSize: "20px",
          padding: "0px 10px",
          borderRadius: "10px",
          marginBottom: "10px",
          border: "none",
        }}
      />
      <div className="absolute w-60 right-16 z-30 top-[83px] flex gap-3">
        <ButtonWithIcon
          buttonName={"Submit"}
          onClick={() => {
            onSubmit(selectedPlace);
          }}
        />
        <ButtonWithIcon
          buttonName={"Cancel"}
          background={"red"}
          onClick={() => {
            onCancel();
          }}
        />
      </div>
      <div id="map" ref={mapRef} style={{ height: "90%", width: "100%" }}></div>
    </div>
  );
};

export default SimpleMap;
