export enum Navigation{
    Login="/",
    SignUp="/signup",
    Guest='/guest',
    ForgotPassword="/forgot_password",
    ResetPassword="/reset_password",
    Dashboard="/dashboard",
    Invalid_Url="/invalid_user/_url"
}

export enum ShowBillingDuringRegistrationEnum {
    ShownAndRequired = "ShownAndRequired",
    ShownAndOptional = "ShownAndOptional",
    NotShown = "NotShown",
  }

  export enum ScreenTypeDashboard {  
    SETTINGS = "SETTINGS",  
    ADD_HOTELS='ADD_HOTELS',
    ADD_MANAGERS='ADD_MANAGERS',
    ADD_CATEGORY='ADD_CATEGORY',
    ADD_AGENTS='ADD_AGENT',
    CALL_LOGS='CALL_LOGS',
    MEETING_HISTORY='MEETING_HISTORY',
    NONE = "",
  }

export  enum screenTypeSettings {
    ACCOUNT = "account",
    PAYMENT = "payment",
    PASSWORD = "password",
  }