import { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

export const StateContext = createContext();
export const intialState = {
  chat: false,
  chart: false,
  userProfile: false,
  notification: false,
};

export const ContextProvider = ({ children }) => {
  const [activeMenu, setActiveMenu] = useState(true);
  const [currentColor, setCurrentColor] = useState("#1A97F5");
  const [isClicked, setIsClicked] = useState(intialState);
  const [screenSize, setScreenSize] = useState(undefined);
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);
  const [isUserLogedIn, setIsUserLogedIn] = useState(false);
  const [superParent, setSuperParent] = useState("");
  const [admin, setAdmin] = useState({});
  const [apartments, setApartments] = useState([]);
  const [isShowAdminControl, setIsShowAdminControl] = useState(true);
  const [currentBuildingType, setCurrentBuildingType] = useState({
    name: "Apartment",
    type: "apartment",
    buildingName: "Manage Tower/ Wings",
    FlatName: "Manage House / Flat",
    addBulding: "Add Tower / Wings",
    addFlat: "Add Flat/House",
    shortName: "manage company",
    buildingTagName: "Tower/Wing",
    flatTagName: "Flat/House Name/No",
  });
  const [subdivisionTreeNavigaitonStack, setSubdivisionTreeNavigaitonStack] =
    useState([{}]);
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [apartmentOfSubdivision, setAPartmentOfSubdivision] = useState({});
  const navigation = () => {};
  const params = () => {};
  const setOrganizationDetails = () => {};
  const setCustomerDetails = () => {};
  const setCustomerToken = () => {};
  const customer = "";
  const organization = "";
  const handleClick = (event) => {
    setIsClicked({ ...intialState, [event]: true });
  };
  const setColor = (color) => {
    setCurrentColor(color);
    localStorage.setItem("colorMode", color);

    setThemeSettings(false);
  };
  const setMode = (e) => {
    setCurrentMode(e.target.value);
    localStorage.setItem("themeMode", e.target.value);
    setThemeSettings(false);
  };
  // const {history} = useRoutes();
  // useEffect(() => {
  //     return () => {
  //       // && history.location.pathname === "any specific path")
  //       console.log("history.action",history.action)
  //       if (history.action === "POP") {
  //         console.log("history.action==pop",history.action)
  //       }
  //     };
  //   }, [history])
  return (
    <StateContext.Provider
      value={{
        activeMenu,
        setActiveMenu,
        currentColor,
        isClicked,
        setIsClicked,
        handleClick,
        screenSize,
        setScreenSize,
        setColor,
        setMode,
        currentColor,
        currentMode,
        setThemeSettings,
        themeSettings,
        navigation,
        params,
        setOrganizationDetails,
        setCustomerDetails,
        setCustomerToken,
        setCookie,
        customer,
        organization,
        isUserLogedIn,
        setIsUserLogedIn,
        cookies,
        admin,
        setAdmin,
        superParent,
        setSuperParent,
        subdivisionTreeNavigaitonStack,
        setSubdivisionTreeNavigaitonStack,
        currentBuildingType,
        setCurrentBuildingType,
        removeCookie,
        apartmentOfSubdivision,
        setAPartmentOfSubdivision,
        apartments,
        setApartments,
        isShowAdminControl,
        setIsShowAdminControl,
      }}
    >
      {" "}
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
