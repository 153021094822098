import React from "react";
import style from "./textFields.module.css";

const TextFieldSimple = ({
  onChange,
  placeholder,
  defaultValue,
  value,
  errorMsg,
  width,
  name,
  errorAbsolute,
  handleKeyPress
}: any) => {
  return (
    <div className={`${style.text_field_simple_container} ${errorAbsolute?"relative mb-2":""}`} style={{width:width?width:"100%"}}>
      <p className="text-neutral-600 text-base font-semibold first-letter:capitalize pl-1">{placeholder}</p>
      <input
        className={style.text_field_simple}
        placeholder={placeholder}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        name={name?name:""}
        onKeyDownCapture={handleKeyPress?handleKeyPress:()=>{}}
      />
      {errorAbsolute? errorMsg.length > 1 && <p
        className={`${style.text_field_simple_error} absolute bottom-[-29px]`}
        style={
          errorMsg.length > 1
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {errorMsg.length > 1 ? errorMsg : "A"}
      </p>:( <p
        className={`${style.text_field_simple_error}`}
        style={
          errorMsg.length > 1
            ? { visibility: "visible" }
            : { visibility: "hidden" }
        }
      >
        {errorMsg.length > 1 ? errorMsg : "A"}
      </p>)}
    </div>
  );
};

export default TextFieldSimple;
