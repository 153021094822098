import ApiClient from "../../../Api/apiClient";

const getUsersByApartmentIdAndStatus = async (
  apartmentId: string | undefined,
  requestStatus: string,
  token: string
) => {
  if (apartmentId) {
    return await ApiClient.getInstance().get(
      `/subdivisionUsers/getSubdivisionUsersByApartmentId/${apartmentId}/status/${requestStatus}`,
      token
    );
  } else {
    return await ApiClient.getInstance().get(
      `/subdivisionUsers/getSubdivisionUsersByApartmentId/all/status/${requestStatus}`,
      token
    );
  }
};
const deleteRequestsApi = async (flatUserId: string, token: string) => {
  return await ApiClient.getInstance().delete(
    `/subdivisionUsers/deleteUserWithSubdivisionUserId/${flatUserId}`,
    token
  );
};
const updatedUserRequestStatus = async (
  data: { requestStatus: string; _id: string; statusUpdatedBy: string },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/subdivisionUsers/updateSubdivisionUserStatus",
    data,
    token
  );
};
export {
  getUsersByApartmentIdAndStatus,
  deleteRequestsApi,
  updatedUserRequestStatus,
};
