import React, { Component } from "react";
import { StateContext } from "../../../context/ContextProvider";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import FlatView from "./FlatView";
import { useNavigate } from "react-router-dom";
import {
  LogOutAdmin,
  getUserToken,
  validateObject,
} from "../../../Globals/Functions";
import {
  addFlatApi,
  deleteFlatApi,
  getFlatByApartmentApi,
  getFlatBySubdivisionApi,
  getQrCodeByFlatIdApi,
} from "./apis";
import toast from "react-hot-toast";

interface Props {
  navigate: any;
  subdivisionId: string;
}
interface State {
  apartmentToAdd: { name: string; flatNo: string };
  apartmentToEdit: { name: string; flatNo: string; _id: string };
  authToken: string;
  loading: string;
  isShowAddApartment: boolean;
  isShowEditApartment: boolean;
  isShowDeleteApartment: boolean;
  apartments: Array<Object>;
  isShowMap: boolean;
  genrateQrLoading: string;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class FlatComponentWrap extends Component<Props, State> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: Props) {
    super(props);
    this.state = {
      apartmentToAdd: {
        name: "",
        flatNo: "",
      },
      apartmentToEdit: {
        name: "",
        flatNo: "",
        _id: "",
      },
      authToken: "",
      loading: loadingEnu.PAGELOADING,
      isShowAddApartment: false,
      isShowEditApartment: false,
      isShowDeleteApartment: false,
      isShowMap: false,
      apartments: [],
      genrateQrLoading: "",
    };
  }
  componentDidMount(): void {
    const { setIsUserLogedIn, superParent }: any = this.context;
    const auth_Token = getUserToken();
    if (auth_Token) {
      this.setState({ authToken: auth_Token });
      this.getFlats(auth_Token, superParent);
    } else {
      LogOutAdmin(this.props.navigate, setIsUserLogedIn);
    }
  }
  onQrButtonPressed = async (flat: any) => {
    if (flat?.home) {
      this.setState({ genrateQrLoading: flat._id });
      const res = await getQrCodeByFlatIdApi(flat?.home);
      console.log(res);
      if (res.data?.qrCodeDetail?._id) {
        this.props.navigate(
          `/dashboard/showQrCode/${res.data?.qrCodeDetail?._id}`
        );
      } else {
        toast("something went wrong!");
      }
    } else {
      toast("something went wrong!");
    }
    this.setState({ genrateQrLoading: "" });
    // return;
  };
  getFlats = async (token: string, superParent: string) => {
    if (this.props.subdivisionId == superParent) {
      const res = await getFlatByApartmentApi(superParent, token);
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({ apartments: res.data });
      }
      this.setState({
        loading: loadingEnu.NONE,
      });
    } else {
      const res = await getFlatBySubdivisionApi(
        this.props.subdivisionId,
        token
      );
      console.log(res);
      if (res.isSuccess && res.statusCode == 200) {
        this.setState({ apartments: res.data });
      }
      this.setState({
        loading: loadingEnu.NONE,
      });
    }
  };
  onShowAddApartment = () => {
    console.log("onShowAddApartment clicked");
    this.setState({ isShowAddApartment: !this.state.isShowAddApartment });
  };
  onShowEditApartment = () => {
    console.log("onShowEditApartment clicked");
    this.setState({ isShowEditApartment: !this.state.isShowEditApartment });
  };
  onShowEditApartmentWithValue = (flat: any) => {
    console.log(flat);
    this.setState({
      apartmentToEdit: { _id: flat._id, name: flat.name, flatNo: flat.flatNo },
    });
    this.setState({ isShowEditApartment: !this.state.isShowEditApartment });
  };
  onAddAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      apartmentToAdd: {
        ...prevState.apartmentToAdd,
        [name]: value,
      },
    }));
  };
  onEditAparmentTextChange = (e: any) => {
    const { name, value } = e.target;
    this.setState((prevState) => ({
      apartmentToEdit: {
        ...prevState.apartmentToEdit,
        [name]: value,
      },
    }));
  };
  deleteApartment = async (flatId: string) => {
    const { superParent }: any = this.context;
    this.setState({ loading: loadingEnu.DELETELOADING });
    const res = await deleteFlatApi(flatId, this.state.authToken);
    console.log(res);
    if (res.isSuccess && res.statusCode == 200) {
      if (this.props.subdivisionId == superParent) {
        const res = await getFlatByApartmentApi(
          superParent,
          this.state.authToken
        );
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ apartments: res.data });
        } else {
          console.log("error while getting flats after adding new one");
        }
        this.setState({
          loading: loadingEnu.NONE,
        });
      } else {
        const res = await getFlatBySubdivisionApi(
          this.props.subdivisionId,
          this.state.authToken
        );
        if (res.isSuccess && res.statusCode == 200) {
          this.setState({ apartments: res.data });
        } else {
          console.log("error while getting flats after adding new one");
        }
        this.setState({
          loading: loadingEnu.NONE,
        });
      }
    } else {
      console.log("errorr while deleting the apartment");
    }
    this.setState({ loading: loadingEnu.NONE });
  };
  addAparment = async () => {
    const { superParent, admin }: any = this.context;
    const isAllValueAreValid = validateObject(this.state.apartmentToAdd, [
      "name",
    ]);
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.ADDBUTTONLOADING });
      const res = await addFlatApi(
        {
          name: this.state.apartmentToAdd.name,
          flatNo: this.state.apartmentToAdd.flatNo,
          apartment: superParent,
          subdivision:
            superParent == this.props.subdivisionId
              ? undefined
              : this.props.subdivisionId,
          createdBy: admin._id,
        },
        this.state.authToken
      );
      console.log("add flat api response", res);
      if (res.isSuccess && res.statusCode == 200) {
        if (this.props.subdivisionId == superParent) {
          const res = await getFlatByApartmentApi(
            superParent,
            this.state.authToken
          );
          if (res.isSuccess && res.statusCode == 200) {
            this.setState({
              apartments: res.data,
              apartmentToAdd: { name: "", flatNo: "" },
            });
            this.onShowAddApartment();
            // here you neeed ot add about some more logic without any coper plan this is geeting load more the cap in more mformare way in any other
          } else {
            console.log("error while getting flats after adding new one");
          }
          this.setState({
            loading: loadingEnu.NONE,
          });
        } else {
          const res = await getFlatBySubdivisionApi(
            this.props.subdivisionId,
            this.state.authToken
          );
          if (res.isSuccess && res.statusCode == 200) {
            this.setState({
              apartments: res.data,
              apartmentToAdd: { name: "", flatNo: "" },
            });
            this.onShowAddApartment();
          } else {
            console.log("error while getting flats after adding new one");
          }
          this.setState({
            loading: loadingEnu.NONE,
          });
        }
      } else {
        console.log("someting is wrong when adding apartment");
      }
    } else {
      return isAllValueAreValid;
    }
  };
  editAparment = async () => {
    const { superParent, admin }: any = this.context;
    const isAllValueAreValid = validateObject(this.state.apartmentToEdit, [
      "name",
    ]);
    if (isAllValueAreValid === "right") {
      this.setState({ loading: loadingEnu.EDITLOADING });
      const res = await addFlatApi(
        {
          _id: this.state.apartmentToEdit?._id,
          name: this.state.apartmentToEdit.name,
          flatNo: this.state.apartmentToEdit.flatNo,
          apartment: superParent,
          subdivision:
            superParent == this.props.subdivisionId
              ? undefined
              : this.props.subdivisionId,
          createdBy: admin?._id,
        },
        this.state.authToken
      );
      console.log("add flat api response", res);
      if (res.isSuccess && res.statusCode == 200) {
        if (this.props.subdivisionId == superParent) {
          const res = await getFlatByApartmentApi(
            superParent,
            this.state.authToken
          );
          if (res.isSuccess && res.statusCode == 200) {
            this.setState({
              apartments: res.data,
              apartmentToEdit: { name: "", flatNo: "", _id: "" },
            });
            this.onShowEditApartment();
          } else {
            console.log("error while getting flats after adding new one");
          }
          this.setState({
            loading: loadingEnu.NONE,
          });
        } else {
          const res = await getFlatBySubdivisionApi(
            this.props.subdivisionId,
            this.state.authToken
          );
          if (res.isSuccess && res.statusCode == 200) {
            this.setState({
              apartments: res.data,
              apartmentToEdit: { name: "", flatNo: "", _id: "" },
            });
            this.onShowEditApartment();
          } else {
            console.log("error while getting flats after adding new one");
          }
          this.setState({
            loading: loadingEnu.NONE,
          });
        }
      } else {
        console.log("someting is wrong when adding apartment");
      }
    } else {
      return isAllValueAreValid;
    }
  };
  render() {
    // if (this.state.loading == loadingEnu.PAGELOADING) {
    //   return <Loading_Page />
    // }
    return <FlatView controller={this as any} />;
  }
}

interface HOCProps {
  subdivisionId: string;
}
const FlatComponent = ({ subdivisionId }: HOCProps) => {
  const navigate = useNavigate();
  return (
    <FlatComponentWrap navigate={navigate} subdivisionId={subdivisionId} />
  );
};

export default FlatComponent;
