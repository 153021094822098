import axios from "axios";
import { ApiClientFetch } from "./apiClientFetch";
import ApiClientAxios from "./apiClientAxios";
import BaseUrl from "./BaseUrl";

export default class ApiClient {
  baseUrl: string;
  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }
  static apiClient = new ApiClient(
    process.env.REACT_APP_API_BASE_URL_LOCAL as string
  );
  static getInstance = () => {
    if (!ApiClient.apiClient) {
      ApiClient.apiClient = new ApiClient(
        process.env.REACT_APP_API_BASE_URL_LOCAL as string
      );
    }
    return ApiClient.apiClient;
  };
  async get(url: string, token?: string) {
    try {
      return token
        ? await ApiClientFetch.getInstance().get(url, token)
        : await ApiClientAxios.getInstance().get(url);
    } catch (error: any) {
      console.log(error);
      return error.response;
      throw new Error(`GET request to ${url} failed: ${error.message}`);
    }
  }

  async post(url: string, data: Object, token?: string) {
    try {
      return token
        ? await ApiClientFetch.getInstance().post(url, data, token)
        : await ApiClientAxios.getInstance().post(url, data);
      const response = await BaseUrl.post(url, data);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return error.response;
      throw new Error(`POST request to ${url} failed: ${error.message}`);
    }
  }

  async put(url: string, data: object, token?: string) {
    try {
      return token
        ? await ApiClientFetch.getInstance().put(url, data, token)
        : await ApiClientAxios.getInstance().put(url, data);
    } catch (error: any) {
      console.log(error);
      return false;
      throw new Error(`PUT request to ${url} failed: ${error.message}`);
    }
  }

  async delete(url: string, token: string) {
    try {
      return token
        ? await ApiClientFetch.getInstance().delete(url, token)
        : await ApiClientAxios.getInstance().delete(url);
    } catch (error: any) {
      console.log(error);
      return false;
      throw new Error(`DELETE request to ${url} failed: ${error.message}`);
    }
  }
}
