import axios from "axios";
import ApiClient from "../../../Api/apiClient";
import { addFlatApi } from "../flat/apis";
import { Doorvi_Base_Url, googleMapsApiKey } from "../../../constants";

const addApartmentApi = async (
  apartment: {
    address: string;
    name: string;
    location: string;
    phoneNumber: string;
    website: string;
    _id?: string;
    apartmentType?: string;
    latitude?: string;
    longitude?: string;
    qrCode?: string;
    logo: string;
  },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/apartments/addEditApartment",
    {
      logo: apartment?.logo ? apartment.logo : undefined,
      qrCode: apartment.qrCode ? apartment.qrCode : undefined,
      address: apartment.address,
      name: apartment.name,
      location: apartment.location,
      phoneNumber: apartment.phoneNumber,
      website: apartment.website,
      _id: apartment._id ? apartment._id : undefined,
      apartmentType: apartment.apartmentType
        ? apartment.apartmentType
        : undefined,
      latitude: apartment.latitude ? apartment.latitude : undefined,
      longitude: apartment.longitude ? apartment.longitude : undefined,
    },
    token
  );
};
const addSubdivisionApi = async (
  apartment: {
    name: string;
    location: string;
    phoneNumber: string;
    parentApartmentSubDivision: string | undefined;
    apartment: string;
    _id?: string;
  },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/subdivision/addEditSubdivision",
    apartment,
    token
  );
};
const assignAdminToApartmentApi = async (
  data: {
    admin: string;
    apartment: string;
    privileges: Array<string>;
    role?: number | undefined;
  },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/apartments/assignAdminApartment",
    data,
    token
  );
};
const getApartmentsApi = async (token: string) => {
  return await ApiClient.getInstance().get("/apartments/apartments", token);
};
const deleteApartmentsByIdApi = async (id: string, token: string) => {
  return await ApiClient.getInstance().delete(
    `/apartments/deleteApartment/${id}`,
    token
  );
};
const getAdminsByApartmentId = async (id: string, token: string) => {
  return await ApiClient.getInstance().get(
    `/apartments/adminsByApartmentId/${id}`,
    token
  );
};
const deleteAdminFromApartmentApi = async (
  adminId: string,
  apartmentId: string,
  token: string
) => {
  return await ApiClient.getInstance().delete(
    `/apartments/removeAdminFromApartment/${adminId}/${apartmentId}`,
    token
  );
};
const addSubdivision = async (
  data: {
    name: string;
    location: string;
    phoneNumber: string;
    parentApartmentSubDivision: string;
    apartment: string;
    _id?: string;
  },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/subdivision/addSubdivision",
    data,
    token
  );
};
const getApartmentByIdApi = async (apartmentId: string, token: string) => {
  return await ApiClient.getInstance().get(
    `/apartments/apartmentById/${apartmentId}`,
    token
  );
};
const getLocationByQureyApi = async (qurey: string) => {
  try {
    const response = await axios.get(
      `https://api.geoapify.com/v1/geocode/autocomplete?text=${qurey}&format=json&apiKey=${googleMapsApiKey}`
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response;
  }
};

// apis for subdivision
const assignAdminToSubdivision = async (
  data: { admin: string; subDivision: string; privileges: Array<string> },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/subdivision/assignAdminSubdivision",
    data,
    token
  );
};
const removeAdminFromSubdivision = async (
  subdivisionId: string,
  adminId: string,
  token: string
) => {
  return await ApiClient.getInstance().delete(
    `/subdivision/deleteSubdivisionAdminAccess/${subdivisionId}/admin/${adminId}`,
    token
  );
};
const deleteSubdivisionById = async (subdivisionId: string, token: string) => {
  return await ApiClient.getInstance().delete(
    `/subdivision/deleteSubdivision/${subdivisionId}`,
    token
  );
};
const getSubdivisionByParentSubdivision = async (
  adminId: string,
  parentSubdivision: string,
  token: string
) => {
  return await ApiClient.getInstance().get(
    `/subdivision/subdivisionsByAdmin/${adminId}/parentSubdivision/${parentSubdivision}`,
    token
  );
};
const getSubdivisionByApartment = async (
  adminId: string,
  apartment: string,
  token: string
) => {
  return await ApiClient.getInstance().get(
    `/subdivision/subdivisionsByAdmin/${adminId}/apartment/${apartment}`,
    token
  );
};
const getAssignedAdminForSubdivision = async (
  subdivision: string,
  token: string
) => {
  return await ApiClient.getInstance().get(
    `/subdivision/adminBySubdivision/${subdivision}`,
    token
  );
};
const genrateQrCodeApi = async (
  data: { type: string; apartmentSubdivisionFlat: any },
  token: string
) => {
  const dataWithSubscriptions = {
    type: data.type,
    apartmentSubdivisionFlat: data.apartmentSubdivisionFlat._id,
    numberOfQrCodes: 1,
    subscriptionPlans: [
      {
        subscriptionUniqueName: "com.doorvi.gold",
        subscriptionValidTillInMonth: -1,
      },
    ],
  };
  console.log(dataWithSubscriptions);
  try {
    const response = await axios.post(
      `${Doorvi_Base_Url}/genrateQRCodes`,
      dataWithSubscriptions
    );
    console.log(response.data);
    if (response.data.isSuccess && response.data.statusCode == 200) {
      if (data.type == "flat") {
        const res = await addFlatApi(
          {
            ...data.apartmentSubdivisionFlat,
            qrCode: response.data.data[0]._id,
          },
          token
        );
        return res;
      } else if (data.type == "apartment") {
        const res = await addApartmentApi(
          {
            ...data.apartmentSubdivisionFlat,
            qrCode: response.data.data[0]._id,
          },
          token
        );
        return res;
      } else {
        const res = await addSubdivisionApi(
          {
            ...data.apartmentSubdivisionFlat,
            qrCode: response.data.data[0]._id,
          },
          token
        );
        return res;
      }
    } else {
      return false;
    }
    // return response.data;
  } catch (error: any) {
    console.log(error);
    return error.response;
  }
};

const removeApartmentLogoApi = async (id: string, token: string) => {
  return await ApiClient.getInstance().delete(
    `/apartments/removeLogo/${id}`,
    token
  );
};
const removeSubdivisionLogoApi = async (id: string, token: string) => {
  return await ApiClient.getInstance().delete(
    `/subdivision/removeLogo/${id}`,
    token
  );
};
// subdivision/subdivisionSettingBySubId/:subdivisionId
// /subdivision/addEditSubdivisionSetting
const updateVisitorSettingsSubdivisionApi = async (
  data: {
    name: string;
    phone: string;
    email: string;
    roomNo: string;
    isSameApartmentCallAllowed: boolean;
    isSameTowerCallAllowed: boolean;
    isEntryApprovalRequired:boolean;
    subDivision: string;
  },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/subdivision/addEditSubdivisionSetting",
    data,
    token
  );
};
const getVisitorSettingsSubdivisionApi = async (id: string, token: string) => {
  return await ApiClient.getInstance().get(
    `/subdivision/subdivisionSettingBySubId//${id}`,
    token
  );
};
const updateVisitorSettingsApi = async (
  data: {
    name: string;
    phone: string;
    email: string;
    roomNo: string;
    isSameApartmentCallAllowed: boolean;
    isSameTowerCallAllowed: boolean;
    isEntryApprovalRequired:boolean;
    apartment: string;
  },
  token: string
) => {
  return await ApiClient.getInstance().post(
    "/apartmentSetting/addEditApartmentSetting",
    data,
    token
  );
};
const getVisitorSettingsApi = async (id: string, token: string) => {
  return await ApiClient.getInstance().get(
    `/apartmentSetting/apartmentSettingByApartment/${id}`,
    token
  );
};
export {
  getVisitorSettingsApi,
  deleteSubdivisionById,
  removeAdminFromSubdivision,
  assignAdminToSubdivision,
  addApartmentApi,
  getApartmentsApi,
  deleteApartmentsByIdApi,
  assignAdminToApartmentApi,
  getAdminsByApartmentId,
  deleteAdminFromApartmentApi,
  addSubdivision,
  getApartmentByIdApi,
  addSubdivisionApi,
  getLocationByQureyApi,
  getSubdivisionByApartment,
  getSubdivisionByParentSubdivision,
  getAssignedAdminForSubdivision,
  genrateQrCodeApi,
  removeApartmentLogoApi,
  removeSubdivisionLogoApi,
  updateVisitorSettingsApi,
  updateVisitorSettingsSubdivisionApi,
  getVisitorSettingsSubdivisionApi
};
