import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

export default function SelectStatus({ onStatusChange, isShowAll }: any) {
  const [status, setStatus] = React.useState(isShowAll ? "all" : "Approved");

  const handleChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
    onStatusChange(event.target.value as string);
  };
  return (
    <Box
      sx={{
        width: 400,
        background: "#fff",
        height: "50px",
        borderRadius: 4,
        display: "flex",
        // alignItems: "end",
        justifyContent: "center",
        paddingTop: "17px",
      }}
    >
      <FormControl style={{ width: "100%" }} size="small" color="primary">
        <InputLabel
          id="demo-simple-select-label"
          style={{ fontSize: 18, width: "100%" }}
        >
          Select Status Type
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Select Status Type"
          onChange={handleChange}
          color="primary"
        >
          <MenuItem value={"Pending"}>Pending</MenuItem>
          {isShowAll && <MenuItem value={"all"}>All</MenuItem>}
          <MenuItem value={"Approved"}>Approved</MenuItem>
          <MenuItem value={"Rejected"}>Rejected</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
