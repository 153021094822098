import React, { MouseEventHandler, useState } from "react";
import TextFieldSimple from "../Neet-Components/Text-Fields/TextFieldSimple";
import { Clear, LocationCitySharp, Map } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import ButtonWithIcon from "../Neet-Components/Buttons/ButtonWithIcon";
import { LockManagerComponentWrap } from "../pages/dashboard/locks/LockManagerComponent";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputLabel } from "@mui/material";

interface Props {
  textFields: Array<{}>;
  onSubmit: Function;
  buttonLoading: boolean;
  onClose: MouseEventHandler<HTMLDivElement>;
  controller: LockManagerComponentWrap;
  isAddBox: boolean;
}
interface State {
  isAllValueAreValid: string;
}
const AddLock = (props: Props) => {
  const { currentColor, currentMode, currentBuildingType } = useStateContext();
  const [isAllValueAreValid, setIsAllValueAreValid] = useState("none");
  return (
    <div className="fixed h-screen w-screen left-0 top-0 bg-[#0000007b] z-10 flex items-center justify-center">
      <div className="bg-white  flex flex-wrap items-center justify-center w-[500px] p-10 pt-20 relative rounded-xl">
        <div
          onClick={props.onClose}
          className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer shadow-black shadow-md"
          style={{ background: currentColor }}
        >
          <Clear sx={{ color: "white" }} />
        </div>
        <div className=" absolute top-[20px] w-[80%] text-center">
          <p className="text-3xl font-semibold text-neutral-700">Add Lock</p>
        </div>
        <TextFieldSimple
          onChange={
            props.isAddBox
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder={"Enter Lock Name"}
          defaultValue={
            props.isAddBox
              ? props.controller.state.lockToAdd.name
              : props.controller.state.lockToEdit.name
          }
          value={
            props.isAddBox
              ? props.controller.state.lockToAdd.name
              : props.controller.state.lockToEdit.name
          }
          errorMsg={
            isAllValueAreValid == "name" ? "Enter a valid lock name" : ""
          }
          width="100%"
          name="name"
          errorAbsolute={true}
        />
        <FormControl sx={{ minWidth: "100%",mt:3 }} size="small">
          <InputLabel id="demo-select-small-label">
            Select Cloud Node
          </InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={props.controller.state.selectedCloudNode}
            label="Select Cloud Node"
            onChange={props.controller.handleChangeCloudNodeChange}
          >
            {props.controller.state.cloudNode.map((cloudNode: any) => {
              return (
                <MenuItem value={cloudNode}>
                  {cloudNode?.name}--{cloudNode?.id}
                </MenuItem>
              );
              // <MenuItem value={cloudNode}>name</MenuItem>;
            })}
            {props.controller.state.cloudNode.length <= 0 && (
              <MenuItem value={""}>{"No Device Found"}</MenuItem>
            )}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: "100%", mb: 3, mt: 3 }} size="small">
          <InputLabel id="demo-select-small-label">Select Device Id</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={props.controller.state.selectedDevice}
            label="Select Device Id"
            onChange={props.controller.handleChangeDeviceIdChange}
          >
            {props.controller.state.devices.map((device: any) => {
              return <MenuItem value={device}>{device?.name}</MenuItem>;
              // <MenuItem value={cloudNode}>name</MenuItem>;
            })}
            {props.controller.state.devices.length <= 0 && (
              <MenuItem value={""}>{"No Device Found"}</MenuItem>
            )}
          </Select>
        </FormControl>
        {/* <TextFieldSimple
          onChange={
            props.isAddBox
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder={"Enter Cloud Node"}
          defaultValue={
            props.isAddBox
              ? props.controller.state.lockToAdd.pdkId
              : props.controller.state.lockToEdit.pdkId
          }
          value={
            props.isAddBox
              ? props.controller.state.lockToAdd.pdkId
              : props.controller.state.lockToEdit.pdkId
          }
          errorMsg={
            isAllValueAreValid == "pdkId" ? "Enter a valid cloudNode" : ""
          }
          width="100%"
          name="pdkId"
        /> */}
        {/* <TextFieldSimple
          onChange={
            props.isAddBox
              ? props.controller.onAddAparmentTextChange
              : props.controller.onEditAparmentTextChange
          }
          placeholder={"Enter Device Id"}
          defaultValue={
            props.isAddBox
              ? props.controller.state.lockToAdd.lockId
              : props.controller.state.lockToEdit.lockId
          }
          value={
            props.isAddBox
              ? props.controller.state.lockToAdd.lockId
              : props.controller.state.lockToEdit.lockId
          }
          errorMsg={
            isAllValueAreValid == "lockId" ? "Enter a valid Device Id" : ""
          }
          width="100%"
          name="lockId"
        /> */}
        <ButtonWithIcon
          buttonName={"Save"}
          onClick={async () => {
            const res = await props.onSubmit();
            console.log(res);
            if (res) {
              setIsAllValueAreValid(res);
            }
          }}
          buttonLoading={props.buttonLoading}
        />
      </div>
    </div>
  );
};

export default AddLock;
