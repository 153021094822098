import ApiClient from "../../Api/apiClient";

export const getOrgDetailsByOrgAliasApi = async (alias: string) => {
  const response = await ApiClient.getInstance().get(
    `/organization/getOrgnizationByAlias/${alias}`
  );
  return response;
};
export const forgotPasswordApi = async (username:string) => {
    const response = await ApiClient.getInstance().post(
      `/admin/forgotPassword`,{username}
    );
    return response;
  };

  export const updatePasswordApi=async(username:string,newPassword:string,oldPassword:string)=>{
    const response = await ApiClient.getInstance().post(
      `/admin/changePassword`,{username,newPassword,oldPassword}

    );
    console.log(response)
    return response;
  }

  // 