import React, { Component } from "react";
import style from "./resetPassword.module.css";
import logo from "./../../Assets/multi-logo.png";
import ButtonWithBackground from "../../Neet-Components/Buttons/ButtonWithBackground";
import TextFieldSimple from "../../Neet-Components/Text-Fields/TextFieldSimple";
import { Navigation } from "../../Utils/eums";
import { isValidPassword, validateEmail, validateObject } from "../../Globals/Functions";
import Loading_Page from "../../Neet-Components/Loading-Page/Loading_Page";
import { getOrgByOrgIdApi, getOrgDetailsByOrgAliasApi, resetPasswordCusApi, validateCusResetPasswordUuidApi } from "./apiHandler";
import TextFieldPassword from "../../Neet-Components/Text-Fields/TextFieldPassword";
import { useNavigate } from "react-router-dom";

interface ResetPasswordState {
  isShowPassMsg: boolean;
  email: string;
  loading: string;
  error: string;
  password: string;
  confirmPassword: string;
  cusId:string;
  orgAlias:string;
}
interface ResetPasswordProps {
  navigation: Function;    
}
enum errorType {
  Email = "email",
  Password = "password",
  Organization = "organization",
  NONE = "none",
  API = "api",
  ALL = "all",
}
export class ResetPassword extends Component<
  ResetPasswordProps,
  ResetPasswordState
> {
  constructor(props: ResetPasswordProps) {
    super(props);
    this.state = {
      isShowPassMsg: !true,
      email: "",
      loading: "",
      error: "",
      password: "",
      confirmPassword: "",
      cusId:"",
      orgAlias:""
    };
  }
  componentDidMount(): void {
   
  }  
  onSubmitPasswordButtonClicked = async () => {    
    this.setState({ loading: "buttonLoading" });
    const resutl=validateObject({password:this.state.password,confirmPassword:this.state.confirmPassword},[""]);
    if (resutl==="right") {
      if (!isValidPassword(this.state.password)) {
        this.setState({ error: "invalidPassword", loading: "" });
        return;
      }
     if(this.state.password===this.state.confirmPassword){
        const res=await resetPasswordCusApi(this.state.cusId,this.state.password);
        console.log(res);
        if(res.isSuccess){
        this.setState({ loading: "" });
        this.props.navigation(Navigation.Login + "/" + this.state.orgAlias);
        }
        else{
        this.setState({ loading: "" });
        }
     }  
    else{
    this.setState({ error: "notMatch" });
    this.setState({ loading: "" });
     } 
    }
    else{
    this.setState({ error: resutl });
    this.setState({ loading: "" });
    return;
    }
  };  
  render() {
    const { isShowPassMsg, loading, error } = this.state;
    const { navigation } = this.props;
    if (loading==="pageLoading") {
      return <Loading_Page />;
    } else
      return (
        <div className={style.forgot_password}>
          <div className={style.logo_title}>
            <img
              src={
                 logo
              }
              className={style.logo}
            />
            <p className={style.title}>
              {isShowPassMsg ? "Warning" : "Update your password"}
            </p>
          </div>
          <div
            className={style.container}
            style={isShowPassMsg ? { width: "485px" } : {}}
          >
            {isShowPassMsg ? (
              <p style={{ textAlign: "center", lineHeight: "24px" }}>
                The link has expired or invalid URL
              </p>
            ) : (
              <>
                <TextFieldPassword
                  placeholder={"New Password"}
                  errorMsg={
                    error === errorType.Password || error === errorType.ALL || error==="notMatch" || error==="invalidPassword"
                    ? error==="notMatch" ?"Password does not match":error==="invalidPassword"?"Password should be contain a minimum of 8 characters 1 uppercase and 1 lowercase":"Incorrect Password"
                      : ""
                  }
                  onChange={(e: any) => {
                    this.setState({
                      password: e.target.value
                    });
                  }}
                />
                <TextFieldPassword
                  placeholder={"re-Type Password"}
                  errorMsg={
                    error === "confirmPassword" || error === errorType.ALL || error==="notMatch"
                      ? error==="notMatch" ?"Password does not match":"Incorrect Password"
                      : ""
                  }
                  onChange={(e: any) => {
                    this.setState({
                        confirmPassword: e.target.value
                    });
                  }}
                />
                <ButtonWithBackground
                  buttonName="Submit"
                  onClick={this.onSubmitPasswordButtonClicked}
                  buttonLoading={loading === "buttonLoading"}
                />
              </>
            )}
          </div>
          {/* <div className={style.link}> */}
          <p className={style.link}>
            <span
              onClick={() => {
                navigation("/");
                console.log("login button pressed")
              }}
              className="link_css"
              style={{marginRight:"20px"}}
            >
              Back To Login 
            </span>{" "}
            <span
              // className={style.link_signup}
              className="link_css"
              onClick={() => {
                navigation(Navigation.ForgotPassword);
              }}
            >
              Forgot Password?
            </span>
          </p>
          {/* </div> */}
        </div>
      );
  }
}



const ResetPasswordWrap = () => {
  const navigate = useNavigate();
  return (
<ResetPassword navigation={navigate}/>
  )
}

export default ResetPasswordWrap