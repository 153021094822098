import ApiClient from "../../Api/apiClient";
import { loginApiInterface } from "../../Utils/api_interfaces";

export const getOrgDetailsByOrgAliasApi = async (alias: string) => {
  const response = await ApiClient.getInstance().get(
    `/organization/getOrgnizationByAlias/${alias}`
  );
  return response;
};
export const getOrgByOrgIdApi=async(orgId:string)=>{
  const res=await ApiClient.getInstance().get(`/organization/orgById/${orgId}`)
  return res;
}
export const loginCustomerApi = async (userDetails:loginApiInterface) => {
    const response = await ApiClient.getInstance().post(
      `/users/login`,userDetails
    );
    return response;
  };

  export const validateCusResetPasswordUuidApi=async(uuid:string)=>{
    const response = await ApiClient.getInstance().get(`/users/customerByForgotPasswordToken/${uuid}`)
    return response;
  }

  export const resetPasswordCusApi=async(cusId:string,password:string)=>{
    const response = await ApiClient.getInstance().put(`/users/resetPassword/${cusId}`,{password})
    return response;
  }