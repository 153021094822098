import axios from "axios";
import ApiClient from "../../../Api/apiClient"

const addFlatApi =async(flat:{name:string,flatNo:string,subdivision:string|undefined,apartment:string,_id?:string,createdBy?:string,statusUpdatedBy?:string},token:string)=>{  
    return ApiClient.getInstance().post('/flats/addEditFlat',flat,token)
}
const deleteFlatApi =async(flatId:string,token:string)=>{
    return ApiClient.getInstance().delete(`/flats/deleteFlat/${flatId}`,token);
}
const getFlatByApartmentApi=async(apartment:string,token:string)=>{
return ApiClient.getInstance().get(`/flats/flats/apartment/${apartment}`,token)
}
const getFlatBySubdivisionApi=async(subdivision:string,token:string)=>{
    return ApiClient.getInstance().get(`/flats/flats/subdivision/${subdivision}`,token)
}

const getQrCodeByFlatIdApi = async (
    homeId: string,
  ) => {  
    try {
      const response = await axios.post("https://api.doorvi.co/api/fetchHomeById", {
        homeId,
        shouldUseNewLogic: true
      });
      console.log(response);
      if (response.data.isSuccess && response.data.statusCode === 200) {
        console.log(response.data)
        return response.data;
    }
    else {
      return false;
    }   
    } catch (error) {
      console.log(error);
      return false;
    }
  };
  
export   {addFlatApi,deleteFlatApi,getFlatByApartmentApi,getFlatBySubdivisionApi,getQrCodeByFlatIdApi}