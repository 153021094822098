import * as React from "react";
import TablePagination from "@mui/material/TablePagination";

interface Props {
  pageNo: number;
  pageSize: number;
  onPageSizeChange: Function;
  onPageNumberChange: Function;
  totalItemSize: number;
}
export default function TablePaginationMui({
  pageNo,
  pageSize,
  onPageNumberChange,
  onPageSizeChange,
  totalItemSize,
}: Props) {
  const [page, setPage] = React.useState(2);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    onPageNumberChange(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onPageSizeChange(parseInt(event.target.value));
  };

  return (
    <TablePagination
      component="div"
      count={totalItemSize}
      page={pageNo}
      onPageChange={handleChangePage}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  );
}
