import { BrowserRouter, Routes, Route } from "react-router-dom";
import { FiSettings } from "react-icons/fi";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Navbar, Footer, Sidebar, ThemeSettings } from "./components";
import "./App.css";
import { useStateContext } from "./context/ContextProvider";
import Login from "./pages/LoginPage/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import ErrorPage from "./pages/errorScreen/ErrorPage";
import {
  AparmetsComponent,
  AdminComponent,
  AssignApartmentComponent,
} from "./pages/dashboard";
import SubdivisionComponent from "./pages/dashboard/subdivision/SubdivisionComponent";
import AssignSubdivisionComponent from "./pages/dashboard/subdivision/AssignSubdivisionComponent";
import ApiClient from "./Api/apiClient";
import { AdminRoles, cookiesSaved } from "./constants";
import {
  NavigationStackProvider,
  useNavigationStack,
} from "./context/NavigationStackContext";
import { useEffect } from "react";
import RequestComponent from "./pages/dashboard/requests/RequestComponent";
import ManageRequest from "./pages/dashboard/requests/ManageRequest";
import QrCode from "./components/QrCode";
import UserComponent from "./pages/dashboard/users/UserComponent";
import { getUserToken } from "./Globals/Functions";
import { getApartmentsApi } from "./pages/dashboard/apartments/api";
import SubdivisionComponentSingle from "./pages/dashboard/subdivision/SubdivisionComponentSingle";
import SubdivisionComponentSingleParent from "./pages/dashboard/subdivision/SubdivisionComponentSingleParent";
import LockManagerComponent from "./pages/dashboard/locks/LockManagerComponent";
import toast, { Toaster } from "react-hot-toast";
import CallLogsComponent from "./pages/dashboard/call logs/CallLogsComponent";
import Settings from "./pages/dashboard/apartments/Settings";
import SettingsSubdivision from "./pages/dashboard/subdivision/Settings";
import ForgotPasswordWrap from "./pages/ForgotPassword/ForgotPassword";
import ResetPasswordWrap from "./pages/ResetPassword/ResetPassword";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { createTheme } from '@mui/material/styles';
import ChangePassword from "./components/changePassword/ChangePassword";


const App = () => {
  const {
    activeMenu,
    themeSettings,
    setThemeSettings,
    currentColor,
    currentMode,
    navigation,
    setOrganizationDetails,
    subdivisionTreeNavigaitonStack,
    organization,
    isUserLogedIn,
    cookies,
    apartments,
    setApartments,
    isShowAdminControl,
    setIsShowAdminControl,
  } = useStateContext();
  useEffect(() => {
    if (isUserLogedIn) {
      getAllApartments();
    }
  }, [isUserLogedIn]);
  const fetchAdminDetailsBySavedToken = async () => {
    const adminId: any = cookies[cookiesSaved.adminId];
    const res = await ApiClient.getInstance().get(
      `/admin/adminById/${adminId}`
    );
    console.log(res);
  };
  // useEffect(() => {
  //   const handleBeforeUnload = (event:any) => {
  //     myCustomFunction();
  //     event.preventDefault();
  //     event.returnValue = "";
  //   };
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  // const myCustomFunction = () => {
  //   console.log('User tried to reload the page');
  // };
  const getAllApartments = async () => {
    const auth_Token: any = getUserToken();
    const res = await getApartmentsApi(auth_Token);
    if (res.isSuccess && res.statusCode == 200 && res.data.length > 0) {
      // alert(res.data[0].role);
      console.log("response of apartments Services inside app file", res.data);
      if (
        res.data[0].role == AdminRoles.Approver ||
        res.data[0].role == AdminRoles.Viewer
      ) {
        setIsShowAdminControl(false);
      }
    }
  };
  const darkTheme = createTheme({
    palette: {
      mode: currentMode === "Dark" ? "dark" : "light",
      primary: {
        main: '#ff5252',
      },
    },    
  });  
  return (    
        <ThemeProvider theme={darkTheme}>
          <CssBaseline />
          <div className={currentMode === "Dark" ? "dark" : ""}>
      <BrowserRouter>
        <NavigationStackProvider>
          <div className="flex relative dark:bg-main-dark-bg w-full">
            <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
              <TooltipComponent content="Settings" position="TopCenter">
                <button
                  type="button"
                  onClick={() => setThemeSettings(true)}
                  style={{ background: currentColor, borderRadius: "50%" }}
                  className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
                >
                  <FiSettings />
                </button>
              </TooltipComponent>
            </div>
            {activeMenu && isUserLogedIn ? (
              <div className="w-52 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
                <Sidebar />
              </div>
            ) : (
              isUserLogedIn && (
                <div className="w-0 dark:bg-secondary-dark-bg">
                  <Sidebar />
                </div>
              )
            )}
            <div
              className={
                activeMenu && isUserLogedIn
                  ? "dark:bg-main-dark-bg  bg-main-bg min-h-screen md:ml-52 w-full  "
                  : "bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 "
              }
            >
              {isUserLogedIn && (
                <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
                  <Navbar />
                </div>
              )}
              <div>
                {themeSettings && <ThemeSettings />}
                <NavigationStackProvider>
                  <Routes>
                    {/* dashboard  */}
                    <Route path="/" element={<Login />} />
                    <Route
                      path="/forgot-password"
                      element={<ForgotPasswordWrap />}
                    />
                    <Route
                      path="/reset-password"
                      element={<ResetPasswordWrap />}
                    />
                    <Route path="*" element={<ErrorPage />} />
                    <Route path="/showQrCode/:qrId" element={<QrCode />} />
                    {isUserLogedIn && (
                      <>
                        <Route
                          path="/dashboard/subdivisions/:parent"
                          element={
                            <SubdivisionComponentSingleParent
                              isShowDropdown={true}
                            />
                          }
                        />
                        <Route
                          path="/dashboard/apartment/settings/:apartmentId"
                          element={<Settings />}
                        />
                        <Route
                          path="/dashboard/change-password"
                          element={<ChangePassword adminToken=""/>}
                        />
                        <Route
                          path="/dashboard/subdivisions"
                          element={
                            <SubdivisionComponentSingle isShowDropdown={true} />
                          }
                        />
                        <Route
                          path="/dashboard/subdivision/settings/:apartmentId"
                          element={<SettingsSubdivision />}
                        />
                        <Route
                          path="/dashboard/apartments"
                          element={<AparmetsComponent />}
                        />
                        <Route
                          path="/dashboard/managelocks/:levelId/:levelType"
                          element={<LockManagerComponent />}
                        />
                        <Route
                          path="/dashboard/admin"
                          element={<AdminComponent />}
                        />
                        <Route
                          path="/dashboard/assign-admin/:apartment"
                          element={<AssignApartmentComponent />}
                        />
                        <Route
                          path="/dashboard/subdivision/:parent"
                          element={<SubdivisionComponent />}
                        />
                        <Route
                          path="/dashboard/subdivision/assign-admin/:apartment"
                          element={<AssignSubdivisionComponent />}
                        />
                        <Route
                          path="/dashboard/requests/manage/:request"
                          element={<ManageRequest />}
                        />
                        <Route
                          path="/dashboard/calllogs"
                          element={<CallLogsComponent />}
                        />
                        <Route
                          path="/dashboard/showQrCode/:qrId"
                          element={<QrCode />}
                        />
                        <Route
                          path="/dashboard/requests"
                          element={<RequestComponent showRequest={true} />}
                        />
                        <Route
                          path="/dashboard/users"
                          element={<UserComponent showRequest={false} />}
                        />
                      </>
                    )}
                  </Routes>
                </NavigationStackProvider>
              </div>
              <Footer />
            </div>
          </div>
        </NavigationStackProvider>
      </BrowserRouter>
      <Toaster />
    </div>
        </ThemeProvider>
      );    
    
};

export default App;
