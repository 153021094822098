import React from "react";
import { Link, NavLink } from "react-router-dom";
import { MdOutlineCancel } from "react-icons/md";
import { links } from "../data/dummy";
import { useStateContext } from "../context/ContextProvider";
import logo from "../Assets/multi-logo.png";

const Sidebar = () => {
  const {
    currentColor,
    activeMenu,
    setActiveMenu,
    screenSize,
    currentBuildingType,
    isShowAdminControl,
    setIsShowAdminControl,
  } = useStateContext();

  const handleCloseSideBar = () => {
    if (activeMenu !== undefined && screenSize <= 900) {
      setActiveMenu(false);
    }
  };

  const activeLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg  text-white  text-md m-2";
  const normalLink =
    "flex items-center gap-5 pl-4 pt-3 pb-2.5 rounded-lg text-md text-gray-700 dark:text-gray-200 dark:hover:text-black hover:bg-light-gray m-2";

  return (
    <div className="ml-3 h-screen md:overflow-hidden overflow-auto md:hover:overflow-auto pb-10">
      {activeMenu && (
        <>
          <div className="flex justify-between items-center">
            <Link
              to="/dashboard/apartments"
              onClick={handleCloseSideBar}
              className="items-center gap-3 mt-4 flex text-xl font-extrabold tracking-tight dark:text-white text-slate-900"
            >
              <img src={logo} className="w-120 h-20" />
            </Link>
            {/* <TooltipComponent content="Menu" position="BottomCenter"> */}
            <button
              type="button"
              onClick={() => setActiveMenu(!activeMenu)}
              style={{ color: currentColor }}
              className="text-xl rounded-full p-3 hover:bg-light-gray mt-4 block md:hidden"
            >
              <MdOutlineCancel />
            </button>
            {/* </TooltipComponent> */}
          </div>
          <div className="mt-10 ">
            {links.map((item) => (
              <div key={item.title}>
                <p className="text-gray-400 dark:text-gray-400 m-3 mt-4 uppercase">
                  {item.title}
                </p>
                {item.links.map((link, index) => {
                  if (index != 1) {
                    return (
                      <NavLink
                        to={`/dashboard/${link.name}`}
                        key={link.name}
                        onClick={handleCloseSideBar}
                        style={({ isActive }) => ({
                          backgroundColor: isActive ? currentColor : "",
                        })}
                        className={({ isActive }) =>
                          isActive ? activeLink : normalLink
                        }
                      >
                        {link.icon}

                        <span className="capitalize ">
                          {link.name == "users"
                            ? "Users Data"
                            : link.name == "requests"
                            ? "New Requests"
                            : link.name == "apartments"
                            ? "Buildings"
                            : link.name == "subdivisions"
                            ? currentBuildingType?.buildingTagName
                            : link.name == "admin"
                            ? "Admin Control"
                            : link.name == "calllogs"
                            ? "Visitor Log"
                            : link.name}
                        </span>
                      </NavLink>
                    );
                  } else {
                    if (isShowAdminControl) {
                      return (
                        <NavLink
                          to={`/dashboard/${link.name}`}
                          key={link.name}
                          onClick={handleCloseSideBar}
                          style={({ isActive }) => ({
                            backgroundColor: isActive ? currentColor : "",
                          })}
                          className={({ isActive }) =>
                            isActive ? activeLink : normalLink
                          }
                        >
                          {link.icon}

                          <span className="capitalize ">
                            {link.name == "users"
                              ? "Master Data"
                              : link.name == "requests"
                              ? "Requests"
                              : link.name == "apartments"
                              ? currentBuildingType?.name
                              : link.name == "subdivisions"
                              ? currentBuildingType?.buildingTagName
                              : link.name == "admin"
                              ? "Admin Control"
                              : link.name}
                          </span>
                        </NavLink>
                      );
                    }
                  }
                })}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Sidebar;
