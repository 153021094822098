import { Base_Url } from "../constants";

export enum ShowBillingDuringRegistrationEnum {
  ShownAndRequired = "ShownAndRequired",
  ShownAndOptional = "ShownAndOptional",
  NotShown = "NotShown",
}

export enum AlertTypeEnum {
  Success = "success",
  Info = "info",
  Warn = "Warning",
  Error = "error",
}

export enum BuildingsTypeEnum {
  Apartment = "Apartment",
  Multi = "Multi",
  Coworking = "Coworking",
  Company = "Company",
}

export const imageBaseUrl = `${Base_Url}/images/image/`;
