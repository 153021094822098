import { Button, CircularProgress, TextField } from "@mui/material";
import React, { Component } from "react";
import "./changePassword.css";
import swal from "sweetalert";
import { ToastContainer, toast } from "react-toastify";
import { updatePasswordApi } from "../../pages/ForgotPassword/apiHandler";
import TextFieldWithBothIcon from "../TextFieldWithBothIcon"
import { StateContext } from "../../context/ContextProvider";
import { Link } from "react-router-dom";

interface ChangePasswordProps {
  adminToken: string;  
}
interface ChangePasswordState {
  loading: boolean;
  error: boolean;
  newPassword: string;
  oldPassword: string;
  reTypeNewPassword: string;
  showOldPassword: boolean;
  showNewPasword: boolean;
  showConfirmPassword: boolean;
  errorType:string;
}
export default class ChangePassword extends Component<
  ChangePasswordProps,
  ChangePasswordState
> {
  static contextType: any = StateContext; // Assign StateContext to static contextType property
  constructor(props: ChangePasswordProps) {
    super(props);
    this.state = {
      loading: false,
      error: false,
      newPassword: "",
      oldPassword: "",
      reTypeNewPassword: "",
      showOldPassword: false,
      showNewPasword: false,
      showConfirmPassword: false,
      errorType:"none"
    };
  }
  onUpdatePasswordButtonPressed = async () => {    
    const {admin}=this.context as any;
    this.setState({ loading: false, error: false });
    if (this.state.newPassword.length > 0 && this.state.newPassword.length>0  && this.state.reTypeNewPassword.length>0) {
      if (this.state.newPassword === this.state.reTypeNewPassword) {
        this.setState({ loading: true });        
        const response:any = await updatePasswordApi(          
          admin.email,
          this.state.newPassword,
          this.state.oldPassword
        );
        if (response.isSuccess) {
          this.setState({ loading: false });
          // this.props.onChangePasswordCancelButtonPressed();
        } 
        else if(response.statusCode===401){
          this.setState({ loading: false, error: true ,errorType:"emptyo"});
        }
        else if (response.message) {
          this.setState({ loading: false, error: true });
          swal({
            title: response.message,
            icon: "error",
            dangerMode: true,
          });
        }
        else{
          swal({
            title: "something went wrong please try again later.",
            icon: "error",
            dangerMode: true,
          });
        }
      } else {
        this.setState({ loading: false, error: true,errorType:"password" });
      }
    } else {
      if(this.state.oldPassword.length<=0){
        this.setState({ error: true, loading: false,errorType:"emptyo" });
      }
      else if(this.state.newPassword.length<=0){
        this.setState({ error: true, loading: false,errorType:"emptyn" });
      }
      else if(this.state.reTypeNewPassword.length<=0){
        this.setState({ error: true, loading: false,errorType:"emptyc" });
      }
      else{
        this.setState({ error: true, loading: false,errorType:"empty" });
      }
    }
    this.setState({loading:false})
  };
  render() {
    const { showConfirmPassword, showNewPasword, showOldPassword, error } =
      this.state;
      const {currentColor}=this.context as any;
    return (
      <div className={`change-password lg:max-w-[84vw] w-[100vw] flex items-center justify-center`}>
        <div className="change-password-container">
          <h2 className="change-password-head">Change Password</h2>
          <TextFieldWithBothIcon
            error={error && this.state.errorType==="empty" || this.state.errorType==="emptyo"}
            errorMsg={this.state.errorType==="empty" ? "Please Enter the valid details.":"Please Enter the valid details."}
            label={""}
            type={showOldPassword ? "text" : "password"}
            firstIconName={
              // !showOldPassword ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />
              ""
            }
            onClickPasswordIcon={() => {
              this.setState({ showOldPassword: !showOldPassword });
            }}
            showPassword={showOldPassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ oldPassword: event.target.value });
            }}
            placeholder="Old Password"
          />
          {/* <TextField
            fullWidth
            id="fullWidth"
            className="change-password-input"
            placeholder="Old Password"
            onChange={(e: any) => {
              this.setState({ oldPassword: e.target.value });
            }}
          /> */}
          <TextFieldWithBothIcon
            error={error && this.state.errorType==="empty" || this.state.errorType==="emptyn"}
            errorMsg={this.state.errorType==="empty" ? "Please Enter the valid details.":"Password do not match."}
            label={""}
            type={showNewPasword ? "text" : "password"}
            firstIconName={
              // !showNewPasword ? <LockOutlinedIcon /> : <LockOpenOutlinedIcon />
              ""
            }
            onClickPasswordIcon={() => {
              this.setState({ showNewPasword: !showNewPasword });
            }}
            showPassword={showNewPasword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ newPassword: event.target.value });
            }}
            placeholder="New Password"
          />
          {/* <TextField
            fullWidth
            id="fullWidth"
            className="change-password-input"
            placeholder="New Password"
            onChange={(e: any) => {
              this.setState({ newPassword: e.target.value });
            }}
          /> */}
          <TextFieldWithBothIcon
            error={error && this.state.errorType==="empty" || this.state.errorType==="emptyc"}
            errorMsg={this.state.errorType==="empty" ? "Please Enter the valid details.":"Password do not match."}
            label={""}
            type={showConfirmPassword ? "text" : "password"}
            firstIconName={
              // !showConfirmPassword ? (
              //   <LockOutlinedIcon />
              // ) : (
              //   <LockOpenOutlinedIcon />
              // )
              ""
            }
            onClickPasswordIcon={() => {
              this.setState({ showConfirmPassword: !showConfirmPassword });
            }}
            showPassword={showConfirmPassword}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              this.setState({ reTypeNewPassword: event.target.value });
            }}
            placeholder="Retype New Password"
          />
          {/* <TextField
            fullWidth
            id="fullWidth"
            className="change-password-input"
            placeholder="Retype New Password"
            onChange={(e: any) => {
              this.setState({ reTypeNewPassword: e.target.value });
            }}
          /> */}
          <div className="change-password-buttons">
            <Link to={"/dashboard/apartment"}>
            <Button
              variant="outlined"
              className="change-password-button-cancel"              
              style={{ textTransform: "none", fontSize: "17px",borderColor:currentColor,color:currentColor }}
            >
              Cancel
            </Button>
            </Link>
            <Button
              variant="contained"
              className="change-password-button-update"
              onClick={this.onUpdatePasswordButtonPressed}
              style={{
                textTransform: "none",
                fontSize: "17px",
                height: "40px",
                background:currentColor
              }}
            >
              {this.state.loading ? (
                <CircularProgress
                  sx={{
                    height: "23px!important",
                    width: "23px!important",
                    color: "#fff",
                  }}
                />
              ) : (
                "Update Password"
              )}
            </Button>
          </div>
        </div>
        <ToastContainer/>
      </div>
    );
  }
}
