import React, { Component } from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import {adminPrivileges } from '../../../constants';
import ButtonWithIcon from '../../../Neet-Components/Buttons/ButtonWithIcon';
import ToggleButtons from '../../../Neet-Components/Toggle_Buttons/ToggleButtons';
import style from "../apartments/settings.module.css";
import CustomAlert from '../../../components/CustomAlert';
import { AssignSubdivisionComponentWrap } from './AssignSubdivisionComponent';

interface Props {
  controller: AssignSubdivisionComponentWrap
}
enum LoadingEnum {
  NONE = "NONE",
  PAGELOADING = "PAGELOADING",
  ASSIGNBUTTONLOADING = "ASSIGNBUTTONLOADING",
  DELETEBUTTONLOADING="DELETEBUTTONLOADING"
}
enum ScreenType{
  ASSIGNNEWADMIN="ASSIGNNEWADMIN",
  MANAGEASSINEDADMIN="MANAGEASSINEDADMIN",
}
export default class AssignSubdivisionView extends Component<Props> {  
  render() {
    const { admin, currentBuildingType }: any = this.props.controller.context;
    return (
      <div className='bg-white w-full h-screen px-10 py-5 flex-row items-center justify-center'>
        <div className={style.toggle_buttons}>
          <ToggleButtons
            buttonId="1"
            buttonName="Assign Roles"
            onClick={() => {
              this.props.controller.onToggleButtonClicked(ScreenType.ASSIGNNEWADMIN);
            }}
            isSelected={
              this.props.controller.state.screenType === ScreenType.ASSIGNNEWADMIN ? true : false
            }
          />          
          <ToggleButtons
            buttonId="3"
            buttonName="Manage Roles"
            onClick={() => {
              this.props.controller.onToggleButtonClicked(ScreenType.MANAGEASSINEDADMIN);
            }}
            isSelected={
              this.props.controller.state.screenType === ScreenType.MANAGEASSINEDADMIN ? true : false
            } 
          />
        </div>
        {this.props.controller.state.screenType==ScreenType.ASSIGNNEWADMIN ? <>
        {/* <h1 className='text-2xl font-semibold mb-6'>Assign Apartment To Admin And Set Roles For Him</h1> */}
        <FormControl sx={{ minWidth: 250, mb: 3 }} size="small">
          <InputLabel id="demo-select-small-label">Select Admin</InputLabel>1
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={this.props.controller.state.selectedAdmin}
            label="Select Admin"
            onChange={this.props.controller.handleChange}
          >
            {this.props.controller.state.admins.map((admin: any, index: number) => (
              <MenuItem value={admin}>{admin?.name ? admin.name : "admin 1"}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <h1>Select Roles</h1>
        <FormGroup>
          {adminPrivileges.map((privilege: { name: string, title: string }, index: number) =>
          (
            <FormControlLabel control={
              <Checkbox              
                checked={this.props.controller.state.privileges.includes(privilege.name)}
                onChange={(e) => this.props.controller.handlePrivilegeChange(e, privilege.name)}
                disabled={this.props.controller.state.selectedAdmin?._id ? false : true}
              />
            } label={privilege.title=="Can Add Apartments"?`Can Add ${currentBuildingType.name}`:privilege.title=="Can Edit Apartments"?`Can Edit ${currentBuildingType.name}`:privilege.title=="Can Add Tower And Floor"?`Can Add / ${currentBuildingType.buildingName}`:privilege.title} />
          )
          )}
        </FormGroup>
        <div className='mt-6 max-w-60'>
          <ButtonWithIcon buttonLoading={this.props.controller.state.loading==LoadingEnum.ASSIGNBUTTONLOADING} buttonName={"Assign Roles"} onClick={this.props.controller.assignApartmentAndRoleToAdmin}/>
        </div>
        </>:<>
        {/* <h1 className='text-2xl font-semibold mb-6'>Manage Admin And Set Roles For Him</h1> */}
        {this.props.controller.state.selectedAdminForAssignedAdmin?.admin?._id ? <FormControl sx={{ minWidth: 250, mb: 3 }} size="small">
          <InputLabel id="demo-select-small-label">Select Admin</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={this.props.controller.state.selectedAdminForAssignedAdmin}
            label="Select Admin"
            onChange={this.props.controller.handleChangeForAssignedAdmin}
          >
            {this.props.controller.state.assignedAdmins.map((admin: any, index: number) => (
              <MenuItem value={admin}>{admin?.  admin.name ? admin.admin.name : "admin 1"}</MenuItem>
            ))}
          </Select>
        </FormControl>:<><InputLabel id="demo-select-small-label">Select Admin</InputLabel><Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={"none"}
            label="Select Admin"
            // onChange={this.props.controller.handleChange}
          >
            <MenuItem value={"none"}>No Admin</MenuItem>
          </Select></>}
        
        <h1 className='mt-3'>Select Roles</h1>
        <FormGroup>
          {adminPrivileges.map((privilege: { name: string, title: string }, index: number) =>
          (
            <FormControlLabel control={
              <Checkbox              
                checked={this.props.controller.state.assignedAdminPrivileges.includes(privilege.name)}
                onChange={(e) => this.props.controller.handlePrivilegeChangeForAssignedAdmin(e, privilege.name)}
                disabled={this.props.controller.state.selectedAdminForAssignedAdmin?.admin?._id ? false : true}
              />
            } label={privilege.title=="Can Add Apartments"?`Can Add ${currentBuildingType.name}`:privilege.title=="Can Edit Apartments"?`Can Edit ${currentBuildingType.name}`:privilege.title=="Can Add Tower And Floor"?`Can Add / ${currentBuildingType.buildingName}`:privilege.title} />
          )
          )}
        </FormGroup>
        <div className='mt-6 max-w-60'>
          {/* <ButtonWithIcon iconName={} buttonLoading={this.props.controller.state.loading==LoadingEnum.DELETEBUTTONLOADING} buttonName={"Remove Admin"} buttonic onClick={()=>{}}/> */}
          
          
          <ButtonWithIcon buttonLoading={this.props.controller.state.loading==LoadingEnum.ASSIGNBUTTONLOADING} buttonName={"Assign Roles"} onClick={this.props.controller.assignApartmentAndRoleToAdminAlredayInApartment}/>
          <div className='bg-red-600 rounded-md text-center my-4'><CustomAlert color={"#fff"} text={"Remove Admin"} onPressDelete={()=>{this.props.controller.deleteAdminFromApartment(this.props.controller.state.selectedAdminForAssignedAdmin.admin._id)}}/></div>
        </div>
        </>}
      </div>
    )
  }
}
