// NavigationStackContext.tsx
import React, { createContext, useState, useContext, useEffect, ReactNode } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useStateContext } from './ContextProvider';

interface NavigationStackContextType {
  navigationStack: string[];
  pushToStack: (path: string) => void;
  popFromStack: () => void;
}

const NavigationStackContext = createContext<NavigationStackContextType | undefined>(undefined);

interface NavigationStackProviderProps {
  children: ReactNode;
}

export const NavigationStackProvider = ({ children }: NavigationStackProviderProps) => {
  const { subdivisionTreeNavigaitonStack,
  setSubdivisionTreeNavigaitonStack } = useStateContext();
  const [navigationStack, setNavigationStack] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    console.log('URL changed:', location.pathname);    
    if (location.pathname.includes("subdivision") && !location.pathname.includes("/subdivision/assign-admin")) {
      console.log("locaiton given", location.pathname)
      console.log(location.pathname.slice(23, location.pathname.length))
      const currentUrlId = location.pathname.slice(23, location.pathname.length);
      if (subdivisionTreeNavigaitonStack.length > 1) {
        const lastPageInStack = subdivisionTreeNavigaitonStack[(subdivisionTreeNavigaitonStack.length - 1)];
        if (lastPageInStack && lastPageInStack.id !== currentUrlId) {
          const updatedStack = subdivisionTreeNavigaitonStack;
          updatedStack.pop();
          console.warn("updatedStack", updatedStack)
          setSubdivisionTreeNavigaitonStack(updatedStack)
        }        
      }
    }
    else if(location.pathname.includes("/subdivision/assign-admin")){            
    }
    else{
      setSubdivisionTreeNavigaitonStack([{name:"Home",id:"none"}])
    }
  }, [location.pathname]);
  
  useEffect(() => {
    const handleNavigation = () => {
      setNavigationStack(prevStack => [...prevStack, location.pathname]);
      console.log(navigationStack);
    };

    window.addEventListener('popstate', handleNavigation);
    window.addEventListener('pushstate', handleNavigation);

    return () => {
      window.removeEventListener('popstate', handleNavigation);
      window.removeEventListener('pushstate', handleNavigation);
    };
  }, [location]);

  const pushToStack = (path: string) => {
    setNavigationStack(prevStack => [...prevStack, path]);
    navigate(path);
    window.history.pushState({ path }, '', path); // Manually push the state for forward navigation
  };

  const popFromStack = () => {
    const previousPath = navigationStack[navigationStack.length - 2]; // Get the previous path
    setNavigationStack(prevStack => prevStack.slice(0, -1));
    navigate(previousPath);
    window.history.back(); // Navigate back using browser's back button
  };

  return (
    <NavigationStackContext.Provider value={{ navigationStack, pushToStack, popFromStack }}>
      {children}
    </NavigationStackContext.Provider>
  );
};

export const useNavigationStack = (): NavigationStackContextType => {
  const context = useContext(NavigationStackContext);
  if (!context) {
    throw new Error('useNavigationStack must be used within a NavigationStackProvider');
  }
  return context;
};
