import ApiClient from "../../../Api/apiClient"

const getRequestsApi = async (adminId: string, requestStatus: string, token: string) => {
   return await ApiClient.getInstance().get(`/subdivisionUsers/getSubdivisonRequestsForAdmin/${adminId}/status/${requestStatus}`, token);
}

const deleteRequestsApi = async (flatUserId: string, token: string) => {
   return await ApiClient.getInstance().delete(`/subdivisionUsers/deleteUserWithSubdivisionUserId/${flatUserId}`, token);
}
const updatedUserRequestStatus = async (data: { requestStatus: string, _id: string, statusUpdatedBy: string }, token: string) => {
   return await ApiClient.getInstance().post("/subdivisionUsers/updateSubdivisionUserStatus", data, token);
}
export { getRequestsApi, deleteRequestsApi, updatedUserRequestStatus }