import { CircularProgress } from '@mui/material'
import { useStateContext } from '../../context/ContextProvider';

const Loading_Page = () => {
  const { activeMenu,isUserLogedIn,currentColor } = useStateContext();    
  return (
    <div className={
      activeMenu && isUserLogedIn
        ? 'dark:bg-main-dark-bg bg-main-bg h-screen flex items-center justify-center'
        : 'bg-main-bg dark:bg-main-dark-bg  w-full min-h-screen flex-2 items-center justify-center new'
    }>
      <CircularProgress style={{color:currentColor}}/>
    </div>
  )
}

export default Loading_Page
