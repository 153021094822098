import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { useStateContext } from "../../../context/ContextProvider";
import { IoMdArrowRoundBack } from "react-icons/io";
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { getUserToken } from "../../../Globals/Functions";
import toast from "react-hot-toast";
import Loading_Page from "../../../Neet-Components/Loading-Page/Loading_Page";
import { getVisitorSettingsSubdivisionApi, updateVisitorSettingsSubdivisionApi } from "../apartments/api";

export enum ApartmentSettingStatus {
  Required = "Required",
  None = "None",
  Optional = "Optional",
  
}
// isEntryApprovalRequired="isEntryApprovalRequired",
export enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  NONE = "NONE",
  UPDATE = "UPDATE",
}

export enum PurposeOfVisitEnum {
  NotRequired = "NotRequired",
  Guest = "Guest",
  Courier = "Courier",
  Delivery = "Delivery",
  FriendsFamily = "FriendsFamily",
  Others = "Others",
}
export const purposeOfVisitArray = [
  { index: 1, value: "NotRequired" },
  { index: 2, value: "Guest" },
  { index: 3, value: "Courier" },
  { index: 4, value: "Delivery" },
  { index: 5, value: "FriendsFamily" },
  { index: 6, value: "Others" },
];

const SettingsSubdivision = () => {
  const navigate = useNavigate();
  const { apartmentId }: any = useParams();
  const { currentColor } = useStateContext();
  const [purposeOfVisit, setPurposeOfVisit] = useState(false);
  const [settings, setSettings] = useState<{
    name: string;
    phone: string;
    email: string;
    roomNo: string;
    isSameApartmentCallAllowed: boolean;
    isSameTowerCallAllowed: boolean;
    isEntryApprovalRequired:boolean;
    canShowMemberName:boolean;
    purposeOfVisit: Array<Object>;
  }>({
    name: ApartmentSettingStatus.None,
    phone: ApartmentSettingStatus.None,
    email: ApartmentSettingStatus.None,
    roomNo: ApartmentSettingStatus.None,
    isSameApartmentCallAllowed: false,
    isSameTowerCallAllowed: false,
    isEntryApprovalRequired:false,
    purposeOfVisit: [],
    canShowMemberName:false,
  });
  const [token, setToken] = useState<string>("");
  const [loading, setLoading] = useState<string>(loadingEnu.PAGELOADING);
  useEffect(() => {
    getSettings();
  }, []);
  const getSettings = async () => {
    setLoading(loadingEnu.PAGELOADING);
    const userToken = getUserToken();
    if (userToken) {
      setToken(userToken);
      const res = await getVisitorSettingsSubdivisionApi(apartmentId, userToken);
      console.log(res, "seeings response");
      if (res.isSuccess && res.statusCode == 200) {
        setSettings(res.data);
        if (res.data.purposeOfVisit.length > 0) {
          setPurposeOfVisit(true);
        }
        setLoading(loadingEnu.NONE);
      } else {
        console.log("error while getting settings");
        setLoading(loadingEnu.NONE);
      }
    }
    setLoading(loadingEnu.NONE);
  };
  const onUpdateAparmentsSettingsButtonPressed = async () => {
    setLoading(loadingEnu.UPDATE);
    const res = await updateVisitorSettingsSubdivisionApi(
      {
        ...settings,
        subDivision: apartmentId,
      },
      token
    );

    console.log(res, "seeings updated response");
    if (res.isSuccess && res.statusCode == 200) {
      toast.success("Settings updated successfully");
      navigate(`/dashboard/subdivision/${apartmentId}`);
    } else {
      toast.error("Something went wrong!");
    }
    setLoading(loadingEnu.NONE);
  };
  if (loading == loadingEnu.PAGELOADING) {
    return <Loading_Page />;
  }
  return (
    <div className="px-10 py-5 flex flex-col">
      <ButtonWithIcon
        buttonName={"Go Back"}
        background={currentColor}
        width={"200px"}
        iconName={<IoMdArrowRoundBack size={24} />}
        onClick={() => {
          navigate(`/dashboard/subdivision/${apartmentId}`);
        }}
      />
      <div className="p-5 flex flex-col mt-10 ml-10">
        <h2 className="text-[30px]">Visitor Form Details</h2>
        <div className="mt-5">
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: "#000" }}
            className="font-bold"
          >
            1. Visitor Name
          </FormLabel>
          <Switch
            value={settings.name == ApartmentSettingStatus.None ? false : true}
            checked={
              settings.name == ApartmentSettingStatus.None ? false : true
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value == "true") {
                setSettings({
                  ...settings,
                  name: ApartmentSettingStatus.None,
                });
              } else {
                setSettings({
                  ...settings,
                  name: ApartmentSettingStatus.Required,
                });
              }
              // setTimeout(() => {
              //   onUpdateAparmentsSettingsButtonPressed();  
              // }, 2000);              
            }}
            color="primary"
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={settings.name}
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                name: event.target.value,
              });
            }}
          >
            <FormControlLabel
              disabled={settings.name == ApartmentSettingStatus.None}
              value={ApartmentSettingStatus.Required}
              control={<Radio />}
              label="Mandatory"
            />
            <FormControlLabel
              disabled={settings.name == ApartmentSettingStatus.None}
              value={ApartmentSettingStatus.Optional}
              control={<Radio />}
              label="Optional"
            />
          </RadioGroup>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: "#000" }}
            className="font-bold"
          >
            2. Visitor Phone No
          </FormLabel>
          <Switch
            value={settings.phone == ApartmentSettingStatus.None ? false : true}
            checked={
              settings.phone == ApartmentSettingStatus.None ? false : true
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value == "true") {
                setSettings({
                  ...settings,
                  phone: ApartmentSettingStatus.None,
                });
              } else {
                setSettings({
                  ...settings,
                  phone: ApartmentSettingStatus.Required,
                });
              }
            }}
            color="primary"
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={settings.phone}
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                phone: event.target.value,
              });
            }}
          >
            <FormControlLabel
              disabled={settings.phone == ApartmentSettingStatus.None}
              value={ApartmentSettingStatus.Required}
              control={<Radio />}
              label="Mandatory"
            />
            <FormControlLabel
              disabled={settings.phone == ApartmentSettingStatus.None}
              value={ApartmentSettingStatus.Optional}
              control={<Radio />}
              label="Optional"
            />
          </RadioGroup>
          <FormLabel
            id="demo-radio-buttons-group-label"
            sx={{ color: "#000" }}
            className="font-bold"
          >
            3. Room No.
          </FormLabel>
          <Switch
            value={
              settings.roomNo == ApartmentSettingStatus.None ? false : true
            }
            checked={
              settings.roomNo == ApartmentSettingStatus.None ? false : true
            }
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.target.value == "true") {
                setSettings({
                  ...settings,
                  roomNo: ApartmentSettingStatus.None,
                });
              } else {
                setSettings({
                  ...settings,
                  roomNo: ApartmentSettingStatus.Required,
                });
              }
            }}
            color="primary"
          />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={settings.roomNo}
            sx={{ display: "flex", flexDirection: "row" }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                roomNo: event.target.value,
              });
            }}
          >
            <FormControlLabel
              disabled={settings.roomNo == ApartmentSettingStatus.None}
              value={ApartmentSettingStatus.Required}
              control={<Radio />}
              label="Mandatory"
            />
            <FormControlLabel
              disabled={settings.roomNo == ApartmentSettingStatus.None}
              value={ApartmentSettingStatus.Optional}
              control={<Radio />}
              label="Optional"
            />
          </RadioGroup>      
       

          <div>
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{ color: "#000" }}
              className="font-bold"
            >
              4. Purpose Of Visit
            </FormLabel>
            <Switch
              value={purposeOfVisit}
              checked={purposeOfVisit}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                console.log(settings);
                console.log(event.target.value)
                if (event.target.value == "true") {
                  setPurposeOfVisit(false);
                  setSettings({
                    ...settings,
                    purposeOfVisit: [],
                  });
                } else {
                  setPurposeOfVisit(true);                  
                }
              }}
              color="primary"
            />
            {purposeOfVisit && (
              <div className="grid grid-cols-2 flex-wrap px-10">
                {purposeOfVisitArray.map((purpose: any) => (
                  <div
                    key={purpose.value}
                    className="flex flex-row w-10 items-center"
                  >
                    <Checkbox
                      // {...label}
                      defaultChecked={settings.purposeOfVisit.includes(
                        purpose.value
                      )}
                      size="medium"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const isChecked = event.target.checked;
                        setSettings((prevSettings) => ({
                          ...prevSettings,
                          purposeOfVisit: isChecked
                            ? [...prevSettings.purposeOfVisit, purpose.value]
                            : prevSettings.purposeOfVisit.filter(
                                (item) => item !== purpose.value
                              ),
                        }));
                      }}
                    />
                    <p className="text-[14px]">{purpose.value}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-5 flex flex-col mt-5 ml-10">
        <h2 className="text-[30px]">Manage What Visitor Can See</h2>
      <div className="mt-5 flex items-center max-w-[300px] justify-between">
          <h1>Show Unit Member / Owner Name</h1>
          <Switch
            value={settings.canShowMemberName}
            checked={settings.canShowMemberName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                canShowMemberName: event.target.checked,
              });
            }}
            color="primary"
          />
        </div>
        </div>
      <div className="p-5 flex flex-col mt-5 ml-10">
        <h2 className="text-[30px]">Manage Visitor Calling Residents</h2>
        <div className="mt-5 flex items-center max-w-[300px] justify-between">
          <h1>Visitor Entry Approval</h1>
          <Switch
            value={settings.isEntryApprovalRequired}
            checked={settings.isEntryApprovalRequired}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                isEntryApprovalRequired: event.target.checked,
              });
            }}
            color="primary"
          />
        </div>        
        {/* <div className="mt-1 flex items-center max-w-[300px] justify-between">
          <h1>Apartment Calling Residents</h1>
          <Switch
            value={settings.isSameApartmentCallAllowed}
            checked={settings.isSameApartmentCallAllowed}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                isSameApartmentCallAllowed: event.target.checked,
              });
            }}
            color="primary"
          />
        </div>
        <div className="mt-1 flex items-center max-w-[300px] justify-between">
          <h1>Tower Calling Residents</h1>
          <Switch
            value={settings.isSameTowerCallAllowed}
            checked={settings.isSameTowerCallAllowed}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSettings({
                ...settings,
                isSameTowerCallAllowed: event.target.checked,
              });
            }}
            color="primary"
          />
        </div> */}
      </div>
      <div className="ml-20 mt-10">
        <ButtonWithIcon
          buttonName={"Submit"}
          background={currentColor}
          width={"200px"}
          onClick={onUpdateAparmentsSettingsButtonPressed}
          buttonLoading={loading == loadingEnu.UPDATE}
        />
      </div>
    </div>
  );
};

export default SettingsSubdivision;
