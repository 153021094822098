import * as React from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { useStateContext } from "../../context/ContextProvider";

export default function LinearIndeterminate() {
  const { currentColor } = useStateContext();
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress
        sx={{
          backgroundColor: "white",
          "& .MuiLinearProgress-bar": {
            backgroundColor: currentColor,
          },
        }}
        // variant="determinate"
      />
    </Box>
  );
}
