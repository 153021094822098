import * as React from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';

interface Props {
    type: string,
    msg?: string,
    title?: string,
}
export default function DescriptionAlert({ title, msg, type }: Props) {
    return (
        <Alert severity={type as any}>
            <AlertTitle>{title ? title : "Success"}</AlertTitle>
            {msg ? msg : "All Good"}
        </Alert>
    );
}