import React, { MouseEventHandler, useState } from 'react'
import TextFieldSimple from '../Neet-Components/Text-Fields/TextFieldSimple';
import { Clear, LocationCitySharp, Map } from '@mui/icons-material';
import { useStateContext } from '../context/ContextProvider';
import ButtonWithIcon from '../Neet-Components/Buttons/ButtonWithIcon';

import { FlatComponentWrap } from '../pages/dashboard/flat/FlatComponent';

interface Props {
    textFields: Array<{}>;
    onSubmit: Function;
    buttonLoading: boolean;
    onClose: MouseEventHandler<HTMLDivElement>;
    controller: FlatComponentWrap;
    isAddBox:boolean;
}
interface State {
    isAllValueAreValid: string;
}
const AddFlat = (props: Props) => {
    const { currentColor, currentMode ,currentBuildingType} = useStateContext();    
    const [isAllValueAreValid, setIsAllValueAreValid] = useState("none")
    return (
        <div className='fixed h-screen w-screen left-0 top-0 bg-[#0000007b] z-10 flex items-center justify-center' >
            <div className='bg-white  flex flex-wrap items-center justify-center w-[500px] p-10 pt-20 relative rounded-xl'>
                <div onClick={props.onClose} className="rounded-full w-10 h-10 flex items-center justify-center absolute right-2 top-2 cursor-pointer shadow-black shadow-md" style={{ background: currentColor }}><Clear sx={{color:"white"}}/></div>
                {/* <TextFieldSimple
                    onChange={props.isAddBox ? props.controller.onAddAparmentTextChange:props.controller.onEditAparmentTextChange}
                    placeholder="Name*"
                    defaultValue={props.isAddBox? props.controller.state.apartmentToAdd.name:props.controller.state.apartmentToEdit.name}
                    value={props.isAddBox? props.controller.state.apartmentToAdd.name:props.controller.state.apartmentToEdit.name}
                    errorMsg={isAllValueAreValid == "name" ? "Enter a valid name" : ""}
                    width="100%"
                    name="name"

                />                                    */}
                <TextFieldSimple
                    onChange={props.isAddBox ? props.controller.onAddAparmentTextChange:props.controller.onEditAparmentTextChange}
                    placeholder={currentBuildingType?.flatTagName?currentBuildingType?.flatTagName:""}
                    defaultValue={props.isAddBox? props.controller.state.apartmentToAdd.flatNo:props.controller.state.apartmentToEdit.flatNo}
                    value={props.isAddBox? props.controller.state.apartmentToAdd.flatNo:props.controller.state.apartmentToEdit.flatNo}
                    errorMsg={isAllValueAreValid == "flatNo" ? "Enter a valid flat number" : ""}
                    width="100%"
                    name="flatNo"
                />
                <ButtonWithIcon buttonName={"Save"} onClick={async() => {
                    const res = await props.onSubmit();
                    console.log(res);
                    if (res) {
                        setIsAllValueAreValid(res);
                    }
                }} buttonLoading={props.buttonLoading} />
            </div>
        </div>
    )
}

export default AddFlat
