import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { MenuItem } from "@mui/material";

interface Apartment {
  apartment: any;
  role: string;
}

interface SelectApartmentProps {
  onStatusChange: (apartment: string | null, role: string | null) => void;
  apartments: Apartment[];
}

export default function SelectApartment({
  onStatusChange,
  apartments,
}: SelectApartmentProps) {
  const [status, setStatus] = React.useState<string>(
    apartments.length > 0
      ? apartments.length > 1
        ? "all"
        : apartments[0].apartment.name
      : "no"
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    if (selectedValue === "all") {
      setStatus("all");
      onStatusChange("all", null);
    } else {
      const selectedApartment = apartments.find(
        (apartment) => apartment.apartment.name === selectedValue
      );
      if (selectedApartment) {
        setStatus(selectedValue);
        onStatusChange(selectedApartment.apartment, selectedApartment.role);
      }
    }
  };

  return (
    <Box
      sx={{
        width: 400,
        background: "#fff",
        height: "50px",
        borderRadius: 4,
        display: "flex",
        justifyContent: "center",
        paddingTop: "17px",
      }}
    >
      <FormControl fullWidth size="small" color="primary">
        <InputLabel id="demo-simple-select-label" style={{ fontSize: 18 }}>
          Select Unit
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={status}
          label="Select Unit"
          onChange={handleChange}
          color="primary"
        >
          {apartments.length <= 0 && (
            <MenuItem value="no">No Apartment Found</MenuItem>
          )}
          {apartments.length === 1 && (
            <MenuItem value={apartments[0].apartment.name}>
              {apartments[0].apartment.name}
            </MenuItem>
          )}
          {apartments.length > 1 && (
            <MenuItem value="all">All Apartments</MenuItem>
          )}
          {apartments.length > 1 &&
            apartments.map((apartment) => (
              <MenuItem
                key={apartment.apartment.name}
                value={apartment.apartment.name}
              >
                {apartment.apartment.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
}
