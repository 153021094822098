import { Edit, Logout } from "@mui/icons-material";
import { useStateContext } from "../context/ContextProvider";
import { saveToLocalStorage } from "../Utils/Utils";
import { Link } from "react-router-dom";

const UserProfile = () => {
  const {
    setIsClicked,
    admin,
    currentColor,
    removeCookie,
    setAdmin,
    setIsUserLogedIn,
  } = useStateContext();
  return (
    <div className="absolute bottom-[-190px] right-[1%] bg-white shadow-orange-200 px-5 py-5 shadowForDIv rounded-md flex-col gap-3 z-50">
      <p className="text-sm text-gray-500">{admin.name}</p>
      <p className="mb-6">{admin.email}</p>
      {/* <div className='flex items-center gap-1 px-5 py-1 rounded-md mb-2 bg-neutral-100'>
        <div><ManageAccountsIcon/></div>
        <div><p>Edit Profile</p></div>
      </div> */}

      <Link to={"/dashboard/change-password"}>
        <div
          className="flex items-center gap-1 px-5 py-1 rounded-md bg-neutral-100 cursor-pointer"
          onClick={() => {
            // window.location.href = "/dashboard/change-password";
          }}
        >
          <div>
            <Edit />
          </div>
          <div>
            <p>Change Password</p>
          </div>
        </div>
      </Link>
      <div className="border-b-gray-200 border-1 my-5 w"></div>
      <button
        className="bg-red-600 px-5 py-1 text-white rounded-md w-full cursor-pointer"
        onClick={() => {
          // setTimeout(() => {

          // }, 1000);
          // removeCookie(cookiesSaved.login);
          // removeCookie(cookiesSaved.adminId);
          // removeCookie(cookiesSaved.auth_Token);
          // setAdmin({});
          // setIsUserLogedIn(false);
          saveToLocalStorage("userDetails", {});
          window.location.href = "/";
        }}
      >
        <Logout />
        Logout
      </button>
    </div>
  );
};

export default UserProfile;
