import { Component } from "react";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { Add, Delete, Edit } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { AdminComponentWrap } from "./AdminComponent";
import AddAdmin from "../../../components/AddAdmin";
import CustomAlert from "../../../components/CustomAlert";
import { AdminPrivileges, AdminRoles } from "../../../constants";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Select } from "@mui/material";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import { StyledDataGrid } from "../apartments/AparmetsView";

interface Props {
  controller: AdminComponentWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class AdminView extends Component<Props> {
  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType }: any = this.props.controller.context;
    const columns: GridColDef[] = [
      { field: "id", headerName: "ID", width: 50 },
      { field: "name", headerName: "Name", width: 100 },
      { field: "phoneNo", headerName: "Phone Number", width: 120 },
      { field: "email", headerName: "Email", width: 120 },
      { field: "username", headerName: "Username", width: 120 },
      // { field: "adminRole", headerName: "Role", width: 150 },
      { field: "password", headerName: "Password", width: 100 },
      // { field: 'apartments', headerName: 'Privileges', width: 250 },
      // { field: 'edit', headerName: 'Edit', width: 100, renderCell: () => { return <div className='flex-row justify-center gap-5 cursor-pointer'><Edit /></div> } },
      // { field: 'manage', headerName: 'Manage Admins', width: 200, renderCell: () => { return <ButtonWithIcon buttonName={"Assign Apartment"} iconName={<Edit />} /> } },
      {
        field: "delete",
        headerName: "Delete",
        width: 60,
        renderCell: (index) => {
          return admin.role != AdminRoles.Viewer &&
            admin.role != AdminRoles.Approver ? (
            <div className="flex-row justify-center gap-5 cursor-pointer">
              <CustomAlert
                onPressDelete={() => {
                  controller.deleteAdmin(index.api.getRow(index.row.id)._id);
                }}
              />
            </div>
          ) : (
            <div className="flex-row justify-center gap-5 cursor-pointer">
              <Delete color="disabled" />
            </div>
          );
        },
      },
      {
        field: "manage",
        headerName: "Assigned " + currentBuildingType.name,
        width: 350,
        align: "center",
        headerAlign: "center",
        renderCell: (index: any) =>
          index.api.getRow(index.row.id).apartments.length <= 0 ? (
            <p>----</p>
          ) : index.api.getRow(index.row.id).apartments.length <= 1 ? (
            <p>
              {index.api.getRow(index.row.id).apartments[0]?.apartment?.name}
            </p>
          ) : (
            <Box
              sx={{
                width: 300,
                background: "#fff",
                paddingLeft: 2,
                paddingRight: 2,
                borderRadius: 4,
                height: 80,
                border: "none",
              }}
            >
              <FormControl
                fullWidth
                size="small"
                color="primary"
                sx={{ border: "none" }}
              >
                <Select
                  // color='primary'
                  value={0}
                  sx={{ border: "none" }}
                >
                  {index.api.getRow(index.row.id).apartments.length <= 0 && (
                    <MenuItem value={0}>--</MenuItem>
                  )}
                  {index.api.getRow(index.row.id).apartments?.length > 0 &&
                    index.api
                      .getRow(index.row.id)
                      .apartments.map((apartmemt: any, i: number) => (
                        <MenuItem value={i}>
                          {apartmemt?.apartment?.name}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
          ),
      },
      // { field: 'admin', headerName: 'Admins', width: 200, renderCell: (index) => { return <ButtonWithIcon buttonName={"Manage Admin"}  iconName={<Edit />} /> } },
    ];
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">
        {controller.state.isShowAddAdmin && (
          <AddAdmin
            controller={controller}
            onClose={controller.onShowAddAdmin}
            onSubmit={controller.addAdmin}
            buttonLoading={
              controller.state.loading == loadingEnu.ADDBUTTONLOADING
                ? true
                : false
            }
            textFields={[]}
          />
        )}
        <div className="flex gap-10 w-60">
          {admin.role != AdminRoles.Viewer &&
            admin.role != AdminRoles.Approver && (
              <ButtonWithIcon
                buttonName={"Add Member"}
                iconName={<Add />}
                onClick={controller.onShowAddAdmin}
              />
            )}
          {/* <ButtonWithIcon buttonName={"Edit Admin"} iconName={<Edit />} /> */}
          {/* <ButtonWithIcon buttonName={"Delete Admin"} iconName={<Delete />} /> */}
        </div>
        <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
          {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
            <div className="h-full">
              <LinearIndeterminate />
              <StyledDataGrid
                columns={columns}
                hideFooterPagination={true}
                style={{ background: "#fff", height: "90%" }}
              />
            </div>
          ) : (
            <StyledDataGrid
              rows={controller.state.admins.map((admin: any, index) => ({
                ...admin,
                id: index + 1,
                adminRole: admin?.role == "5000" ? "Owner" : "--",
              }))}
              columns={columns}
              style={{ background: "#fff", height: "100%" }}
              // hideFooterPagination={true}
              autoPageSize={true}
                  pagination={true}
              rowSelection={false}
              // checkboxSelection
            />
          )}
        </div>
      </div>
    );
  }
}

export default AdminView;
