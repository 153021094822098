import { Component } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SelectStatus from "../../../components/SelectStatus";
import { UserComponentWrap } from "./UserComponent";
import SelectApartment from "../../../components/SelectApartment";
import { Delete, Edit } from "@mui/icons-material";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
// import moment from "moment-timezone";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import { AdminPrivileges, AdminRoles } from "../../../constants";
import moment from "moment";
import { StyledDataGrid } from "../apartments/AparmetsView";
import { TbReload } from "react-icons/tb";

interface Props {
  controller: UserComponentWrap;
}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
}
export class UserView extends Component<Props> {


  render() {
    const { controller } = this.props;
    const { admin, currentBuildingType,currentColor }: any = this.props.controller.context;
    const columns: GridColDef[] = this.props.controller.props.showRequest
      ? [
          { field: "userName", headerName: "User Name", width: 130 },
          { field: "email", headerName: "User Email/Phone No", width: 150 },
          {
            field: "subdivision",
            headerName: "Requested For",
            width: 170,
          },
          // {
          //   field: "flatNo",
          //   headerName: currentBuildingType.flatTagName
          //     ? currentBuildingType.flatTagName
          //     : "",
          //   width: 100,
          // },
          // { field: 'flatUserType', headerName: 'User Type Of Flat', width: 170 },
          // { field: "requestStatus", headerName: "Request Status", width: 120 },
          {
            field: "timestamp",
            headerName: "Created At",
            width: 180,
            renderCell: (index) =>
              moment(new Date(index.api.getRow(index.row.id).createdAt)).format(
                " DD-MM-YYYY hh:mm A"
              ),
              
          },
          {
            field: "manage",
            headerName: "Manage Request",
            width: 150,
            // headerAlign: "center",
            // align: "center",
            renderCell: (index) => {
              return this.props.controller.state.adminRole !=
                AdminRoles.Viewer ? (
                <ButtonWithIcon
                  buttonName={""}
                  width={"60px"}
                  onClick={() => {
                    console.log(this.props.controller.state.adminRole);
                    // return;
                    this.props.controller.props.navigate(
                      `/dashboard/requests/manage/${
                        index.api.getRow(index.row.id)._id
                      }`
                    );
                  }}
                  iconName={<Edit />}
                />
              ) : (
                <ButtonWithIcon
                  buttonName={""}
                  background={"lightgray"}
                  width={"60px"}
                  iconName={<Edit />}
                />
              );
            },
          },
          // { field: 'delete', headerName: 'Delete', width: 150, renderCell: (index) => { return <ButtonWithIcon buttonName={""} width={"100px"} onClick={() => {this.props.controller.props.navigate(`/dashboard/requests/manage/${index.api.getRow(index.row.id)._id}`)}} iconName={<Delete />} /> } },
          // deleteRequestsApi
          // { field: 'createdAt', headerName: 'Created At', width: 150,renderCell: (index: any) => (index.api.getRow(index.row.id) },
        ]
      : [
          { field: "userName", headerName: "User Name", width: 130 },
          { field: "email", headerName: "User Email/Phone No", width: 150 },
          {
            field: "subdivision",
            headerName: "Requested For",
            width: 170,
          },
          // {
          //   field: "flatNo",
          //   headerName: currentBuildingType.flatTagName
          //     ? currentBuildingType.flatTagName
          //     : "",
          //   width: 100,
          // },
          // { field: 'flatUserType', headerName: 'User Type Of Flat', width: 170 },
          // { field: "requestStatus", headerName: "Request Status", width: 120 },
          {
            field: "timestamp",
            headerName: "Created At",
            width: 150,
            renderCell: (index) =>
              moment(new Date(index.api.getRow(index.row.id).createdAt)).format(
                " DD-MM-YYYY hh:mm A"
              ),              
          },
          // { field: 'timestamp', headerName: 'Created At', width: 150, renderCell: (index) => {return <p>{this.formatDate(index.api.getRow(index.row.id).createdAt)}</p>} },
          // { field: 'timestamp', headerName: 'Created At', width: 150, renderCell: (index) => {
          //   const createdAt = index.api.getRow(index.row.id).createdAt;
          //   const formattedDate = this.formatDate(createdAt);
          //   return<p>{formattedDate as any}</p>;
          // }},
          // { field: 'delete', headerName: 'Delete', width: 150, renderCell: (index) => { return <ButtonWithIcon buttonName={""} width={"100px"} onClick={() => {this.props.controller.props.navigate(`/dashboard/requests/manage/${index.api.getRow(index.row.id)._id}`)}} iconName={<Delete />} /> } },
          // deleteRequestsApi
          // { field: 'createdAt', headerName: 'Created At', width: 150,renderCell: (index: any) => (index.api.getRow(index.row.id) },
        ];
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw]">
        <>
        <div className="rounded-md relative">
        <ButtonWithIcon
        buttonName={"Refresh Page"}
        background={currentColor}
        width={"200px"}
        iconName={<TbReload size={24} />}
        onClick={() => {
          this.props.controller.componentDidMount();
        }}
      />
      </div>
          <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll relative">
            {this.props.controller.state.loading != loadingEnu.PAGELOADING && (
              <div className="flex gap-2 w-60 absolute z-10 right-[10px] -top-2">
                <SelectStatus
                  onStatusChange={this.props.controller.onStatusChange}
                  isShowAll={true}
                />
                <SelectApartment
                  onStatusChange={this.props.controller.onApartmentStatusChange}
                  apartments={this.props.controller.state.apartments as any}
                />
              </div>
            )}
            {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
              <div className="h-full">
                <LinearIndeterminate />
                <StyledDataGrid
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "90%" }}
                />
              </div>
            ) : (
              <StyledDataGrid
                rows={controller.state.requests.map((row: any, index: any) => ({
                  ...row,
                  id: index + 1,
                  userName: row?.user?.name || "",
                  email: row?.user?.email
                    ? row?.user?.email
                    : row?.user?.phoneNumber
                    ? row?.user?.phoneNumber
                    : "",
                  apartment: row.apartment.name || "",
                  subdivision: row.subdivision?.parentApartmentSubDivision
                    ? row.subdivision?.parentApartmentSubDivision?.name +
                      " / " +
                      row?.subdivision?.name
                    : row?.subdivision?.name,
                  flatNo: row?.flat?.flatNo || "",
                }))}
                columns={columns}
                // hideFooterPagination={true}
              autoPageSize={true}
              pagination={true}
                style={{ background: "#fff", height: "100%" }}
                // hideFooterPagination={true}
              />
            )}
          </div>
        </>
      </div>
    );
  }
}

export default UserView;
