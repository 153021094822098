import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { Base_Url } from "../constants/index";
// export const BASE_URL = process.env.NODE_ENV==="production" ? process.env.REACT_APP_API_BASE_URL_PROD:process.env.REACT_APP_API_BASE_URL_LOCAL;
export const BASE_URL = Base_Url;
const BaseUrl = axios.create({
  baseURL: BASE_URL,
  timeout: 6000,
  headers: {
    "Cache-Control": "no-cache",
  },
});
export default BaseUrl;
