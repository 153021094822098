import React from "react";
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
} from "@mui/material";
import "./textField.css";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface TypePropTextFieldWithBothButton {
  error: boolean;
  errorMsg: string;
  label: string;
  defaultValue?: string;
  type: string;
  firstIconName: any;
  onChange: any;
  onClickPasswordIcon: () => void;
  showPassword: boolean;
  placeholder: string;
}
const TextFieldWithBothIcon = ({
  error,
  errorMsg,
  label,
  defaultValue,
  type,
  firstIconName,
  onChange,
  onClickPasswordIcon,
  showPassword,
  placeholder,
}: TypePropTextFieldWithBothButton) => {
  return (
    <div className="text-field">
      <div className="input-label">{label}</div>
      <TextField
        sx={{
          width: "100%",
          ":focus":{
            borderColor: error? "#FF0000" : "#000000",
            borderWidth: error? "1px" : "0.5px",
          }
        }}
        error={error}
        // id="outlined-error-helper-text"
        defaultValue={defaultValue ? defaultValue : null}
        placeholder={placeholder}
        helperText={error ? errorMsg : ""}
        type={type}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{firstIconName}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={onClickPasswordIcon}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onChange}
      />
    </div>
  );
};

export default TextFieldWithBothIcon;
