import React, { Component } from "react";
import { SubdivisionComponentWrap } from "./SubdivisionComponent";
import ButtonWithIcon from "../../../Neet-Components/Buttons/ButtonWithIcon";
import { Add, Delete, Edit, QrCode } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddSubdivision from "../../../components/AddSubdivision";
import { AdminPrivileges, AdminRoles, LevelsForLock } from "../../../constants";
import FlatComponent from "../flat/FlatComponent";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { imageBaseUrl } from "../../../Utils/Constants";
import style from "../apartments/settings.module.css";
import { BsBuildingGear } from "react-icons/bs";
import LinearIndeterminate from "../../../Neet-Components/Loading-Page/LinearLoader";
import CustomAlert from "../../../components/CustomAlert";
import SelectApartment from "../../../components/SelectApartment";
import { IoMdLock } from "react-icons/io";
import { StyledDataGrid } from "../apartments/AparmetsView";
import { IoSettingsSharp } from "react-icons/io5";

interface Props {
  controller: SubdivisionComponentWrap;
}
interface State {}
enum loadingEnu {
  PAGELOADING = "PAGELOADING",
  ADDBUTTONLOADING = "ADDBUTTONLOADING",
  EDITLOADING = "EDITLOADING",
  DELETELOADING = "DELETELOADING",
  NONE = "NONE",
  REMOVELOGO = "REMOVELOGO",
}
enum ScreenType {
  ManageSubdivision = "ManageSubdivision",
  ManageFlats = "ManageFlats",
}
export default class SubdivisionVIew extends Component<Props, State> {
  render() {
    const { controller } = this.props;
    const {
      admin,
      currentBuildingType,
      subdivisionTreeNavigaitonStack,
      currentColor,
      setSubdivisionTreeNavigaitonStack,
      apartmentOfSubdivision,
      apartments,
    }: any = controller.context;
    console.log(
      "apartmentOfSubdivision, in apartmentOfSubdivision,",
      apartmentOfSubdivision
    );
    // console.log(controller.state.apartments.map((row: any, index) => ({ ...row, id: index + 1, manage: <ButtonWithIcon buttonName={"Edit Apartments"} iconName={<Edit />} />, website: row.apartment.website, location: row.apartment.location, name: row.apartment.name, phoneNumber: row.apartment.phoneNumber, privileges: row.privileges.map((privilege: any, index: number) => (privilege + " ")) })))
    const columns: GridColDef[] =
      subdivisionTreeNavigaitonStack.length > 2 ||
      currentBuildingType.name == "Company" ||
      currentBuildingType.name == "Hotel"
        ? admin.lockCredentials.length > 0
          ? [
              { field: "id", headerName: "S.No", width: 50 },
              {
                field: "name",
                headerName: currentBuildingType?.flatTagName
                  ? currentBuildingType.flatTagName
                  : "",
                width: 170,
              },
              {
                field: "edit",
                headerName: "Edit",
                width: 50,
                renderCell: (index) => {
                  return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                    apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                    <div
                      className="flex-row justify-center gap-5 cursor-pointer"
                      onClick={() => {
                        this.props.controller.onShowEditApartmentWithValue(
                          index.api.getRow(index.row.id)
                        );
                      }}
                    >
                      <Edit />
                    </div>
                  ) : (
                    <Edit color={"disabled"} />
                  );
                },
              },
              {
                field: "delete",
                headerName: "Delete",
                width: 70,
                renderCell: (index) => {
                  return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                    apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                    <div className="flex-row justify-center gap-5 cursor-pointer">
                      <CustomAlert
                        onPressDelete={() => {
                          console.log(index.api.getRow(index.row.id));
                          controller.deleteApartment(
                            index.api.getRow(index.row.id)?.subDivision?._id
                          );
                        }}
                      />
                    </div>
                  ) : (
                    <Delete color={"disabled"} />
                  );
                },
              },
              {
                field: "Qr Code",
                headerName: "QR Code",
                width: 80,
                renderCell: (index) => {
                  return true ? (
                    <ButtonWithIcon
                      buttonName={""}
                      width={"70px"}
                      onClick={() => {
                        this.props.controller.onQrButtonPressed(
                          index.api.getRow(index.row.id)
                        );
                      }}
                      iconName={<QrCode />}
                    />
                  ) : (
                    <Delete color={"disabled"} />
                  );
                },
              },
              {
                field: "manageLocks",
                headerName: "Locks",
                width: 80,
                renderCell: (index) => {
                  return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                    apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                    <ButtonWithIcon
                      width={"70px"}
                      buttonName={""}
                      onClick={() => {
                        controller.props.navigate(
                          `/dashboard/managelocks/${
                            index.api.getRow(index.row.id)?.subDivision?._id
                          }/${LevelsForLock.SUBDIVISION}`
                        );
                      }}
                      iconName={<IoMdLock size={25} />}
                    />
                  ) : (
                    <ButtonWithIcon
                      buttonName={""}
                      background={"gray"}
                      width={"70px"}
                      iconName={<IoMdLock size={25} />}
                    />
                  );
                },
              },
              // {
              //   field: "setting",
              //   headerName: "Settings",
              //   width: 80,
              //   renderCell: (index) => {
              //     return index.api.getRow(index.row.id).role !=
              //       AdminRoles.Viewer &&
              //       index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
              //       <ButtonWithIcon
              //         width={"60px"}
              //         buttonName={""}
              //         background={"#fa454"}
              //         onClick={() => {
              //           controller.props.navigate(
              //             `/dashboard/apartment/settings/${
              //               index.api.getRow(index.row.id).aparmentId
              //             }`
              //           );
              //         }}
              //         iconName={<IoSettingsSharp size={25} />}
              //       />
              //     ) : (
              //       <ButtonWithIcon
              //         buttonName={""}
              //         background={"lightgray"}
              //         width={"60px"}
              //         iconName={<IoMdLock size={25} />}
              //       />
              //     );
              //   },
              // },
            ]
          : [
              { field: "id", headerName: "S.No", width: 50 },
              {
                field: "name",
                headerName: currentBuildingType?.flatTagName
                  ? currentBuildingType.flatTagName
                  : "",
                width: 170,
              },
              {
                field: "edit",
                headerName: "Edit",
                width: 50,
                renderCell: (index) => {
                  return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                    apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                    <div
                      className="flex-row justify-center gap-5 cursor-pointer"
                      onClick={() => {
                        this.props.controller.onShowEditApartmentWithValue(
                          index.api.getRow(index.row.id)
                        );
                      }}
                    >
                      <Edit />
                    </div>
                  ) : (
                    <Edit color={"disabled"} />
                  );
                },
              },
              {
                field: "Qr Code",
                headerName: "QR Code",
                width: 80,
                renderCell: (index) => {
                  return true ? (
                    <ButtonWithIcon
                      buttonName={""}
                      width={"70px"}
                      onClick={() => {
                        this.props.controller.onQrButtonPressed(
                          index.api.getRow(index.row.id)
                        );
                      }}
                      iconName={<QrCode />}
                    />
                  ) : (
                    <Delete color={"disabled"} />
                  );
                },
              },
              // {
              //   field: "setting",
              //   headerName: "Settings",
              //   width: 80,
              //   renderCell: (index) => {
              //     return index.api.getRow(index.row.id).role !=
              //       AdminRoles.Viewer &&
              //       index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
              //       <ButtonWithIcon
              //         width={"60px"}
              //         buttonName={""}
              //         background={"#fa454"}
              //         onClick={() => {
              //           controller.props.navigate(
              //             `/dashboard/apartment/settings/${
              //               index.api.getRow(index.row.id).aparmentId
              //             }`
              //           );
              //         }}
              //         iconName={<IoSettingsSharp size={25} />}
              //       />
              //     ) : (
              //       <ButtonWithIcon
              //         buttonName={""}
              //         background={"lightgray"}
              //         width={"60px"}
              //         iconName={<IoMdLock size={25} />}
              //       />
              //     );
              //   },
              // },
            ]
        : admin.lockCredentials.length > 0
        ? [
            {
              field: "logo",
              headerName: "Logo",
              width: 120,
              headerAlign: "center",
              renderCell: (index) => {
                return index.api.getRow(index.row.id)?.subDivision
                  ?.thumbLogo ? (
                  <div className={style.logo_container}>
                    <img
                      src={
                        imageBaseUrl +
                        index.api.getRow(index.row.id).subDivision.thumbLogo
                      }
                    />
                  </div>
                ) : (
                  <div className={style.logo_container}>
                    <p>--</p>
                  </div>
                );
              },
            },
            {
              field: "name",
              headerName: currentBuildingType?.buildingTagName
                ? currentBuildingType.buildingTagName
                : "",
              width: 180,
            },
            {
              field: "edit",
              headerName: "Edit",
              width: 50,
              renderCell: (index) => {
                return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                  apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                  <div
                    className="flex-row justify-center gap-5 cursor-pointer"
                    onClick={() => {
                      this.props.controller.onShowEditApartmentWithValue(
                        index.api.getRow(index.row.id)
                      );
                    }}
                  >
                    <Edit />
                  </div>
                ) : (
                  <Edit color={"disabled"} />
                );
              },
            },
            // {
            //   field: "Remove Logo",
            //   headerName: "Remove Logo",
            //   width: 100,
            //   renderCell: (index) => {
            //     return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
            //       apartmentOfSubdivision.role !== AdminRoles.Approver ? (
            //       <div className="flex-row justify-center gap-5 cursor-pointer">
            //         <CustomAlert
            //           loading={
            //             controller.state.loading == loadingEnu.REMOVELOGO
            //           }
            //           msg={"Do you want to remove logo?"}
            //           onPressDelete={() => {
            //             controller.removeLogo(
            //               index.api.getRow(index.row.id)?.subDivision?._id
            //             );
            //           }}
            //         />
            //       </div>
            //     ) : (
            //       <Delete color={"disabled"} />
            //     );
            //   },
            // },
            {
              field: "delete",
              headerName: "Delete",
              width: 70,
              renderCell: (index) => {
                return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                  apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                  <div className="flex-row justify-center gap-5 cursor-pointer">
                    <CustomAlert
                      onPressDelete={() => {
                        console.log(index.api.getRow(index.row.id));
                        controller.deleteApartment(
                          index.api.getRow(index.row.id)?.subDivision?._id
                        );
                      }}
                    />
                  </div>
                ) : (
                  <Delete color={"disabled"} />
                );
              },
            },
            {
              field: "Qr Code",
              headerName: "QR Code",
              width: 80,
              headerAlign: "center",
              align: "center",
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    width={"70px"}
                    onClick={() => {
                      this.props.controller.onQrButtonPressed(
                        index.api.getRow(index.row.id)
                      );
                    }}
                    iconName={<QrCode />}
                  />
                );
              },
            },
            // {
            //   field: "setting",
            //   headerName: "Settings",
            //   width: 80,
            //   renderCell: (index) => {
            //     return index.api.getRow(index.row.id).role !=
            //       AdminRoles.Viewer &&
            //       index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
            //       <ButtonWithIcon
            //         width={"60px"}
            //         buttonName={""}
            //         background={"#fa454"}
            //         onClick={() => {
            //           controller.props.navigate(
            //             `/dashboard/subdivision/settings/${
            //               index.api.getRow(index.row.id)?.subDivision?._id
            //             }`
            //           );
            //         }}
            //         iconName={<IoSettingsSharp size={25} />}
            //       />
            //     ) : (
            //       <ButtonWithIcon
            //         buttonName={""}
            //         background={"lightgray"}
            //         width={"60px"}
            //         iconName={<IoMdLock size={25} />}
            //       />
            //     );
            //   },
            // },
            // { field: 'manage', headerName: 'Manage Admins', width: 200, renderCell: (index) => { return <ButtonWithIcon buttonName={"Manage Admin"} onClick={() => { controller.props.navigate(`/dashboard/subdivision/assign-admin/${index.api.getRow(index.row.id).aparmentId}`) }} iconName={<Edit />} /> } },
            {
              field: "subdivision",
              align: "right",
              headerAlign: "left",
              headerName: false
                ? "Subdivision"
                : currentBuildingType?.FlatName
                ? currentBuildingType.FlatName
                : "",
              width: 200,
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    onClick={() => {
                      const {
                        subdivisionTreeNavigaitonStack,
                        setSubdivisionTreeNavigaitonStack,
                      }: any = this.props.controller.context;
                      setSubdivisionTreeNavigaitonStack([
                        ...subdivisionTreeNavigaitonStack,
                        {
                          name: index.api.getRow(index.row.id).name,
                          id: index.api.getRow(index.row.id).aparmentId,
                        },
                      ]);
                      if (this.props.controller.props.isShowDropdown) {
                        controller.props.navigate(
                          `/dashboard/subdivisions/${
                            index.api.getRow(index.row.id).aparmentId
                          }`
                        );
                      } else {
                        controller.props.navigate(
                          `/dashboard/subdivision/${
                            index.api.getRow(index.row.id).aparmentId
                          }`
                        );
                      }
                    }}
                    iconName={<BsBuildingGear size={24} />}
                    width={"70px"}
                  />
                );
              },
            },
            {
              field: "manageLocks",
              headerName: "Locks",
              width: 130,
              renderCell: (index) => {
                return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                  apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                  <ButtonWithIcon
                    width={"80px"}
                    buttonName={""}
                    onClick={() => {
                      controller.props.navigate(
                        `/dashboard/managelocks/${
                          index.api.getRow(index.row.id)?.subDivision?._id
                        }/${LevelsForLock.SUBDIVISION}`
                      );
                    }}
                    iconName={<IoMdLock size={25} />}
                  />
                ) : (
                  <ButtonWithIcon
                    buttonName={""}
                    background={"gray"}
                    width={"70px"}
                    iconName={<IoMdLock size={25} />}
                  />
                );
              },
            },            
          ]
        : [
            {
              field: "logo",
              headerName: "Logo",
              width: 120,
              headerAlign: "center",
              renderCell: (index) => {
                return index.api.getRow(index.row.id)?.subDivision
                  ?.thumbLogo ? (
                  <div className={style.logo_container}>
                    <img
                      src={
                        imageBaseUrl +
                        index.api.getRow(index.row.id).subDivision.thumbLogo
                      }
                    />
                  </div>
                ) : (
                  <div className={style.logo_container}>
                    <p>--</p>
                  </div>
                );
              },
            },
            {
              field: "name",
              headerName: currentBuildingType?.buildingTagName
                ? currentBuildingType.buildingTagName
                : "",
              width: 180,
            },
            {
              field: "edit",
              headerName: "Edit",
              width: 50,
              renderCell: (index) => {
                return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                  apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                  <div
                    className="flex-row justify-center gap-5 cursor-pointer"
                    onClick={() => {
                      this.props.controller.onShowEditApartmentWithValue(
                        index.api.getRow(index.row.id)
                      );
                    }}
                  >
                    <Edit />
                  </div>
                ) : (
                  <Edit color={"disabled"} />
                );
              },
            },
            // {
            //   field: "Remove Logo",
            //   headerName: "Remove Logo",
            //   width: 100,
            //   renderCell: (index) => {
            //     return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
            //       apartmentOfSubdivision.role !== AdminRoles.Approver ? (
            //       <div className="flex-row justify-center gap-5 cursor-pointer">
            //         <CustomAlert
            //           loading={
            //             controller.state.loading == loadingEnu.REMOVELOGO
            //           }
            //           msg={"Do you want to remove logo?"}
            //           onPressDelete={() => {
            //             controller.removeLogo(
            //               index.api.getRow(index.row.id)?.subDivision?._id
            //             );
            //           }}
            //         />
            //       </div>
            //     ) : (
            //       <Delete color={"disabled"} />
            //     );
            //   },
            // },
            {
              field: "delete",
              headerName: "Delete",
              width: 70,
              renderCell: (index) => {
                return apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                  apartmentOfSubdivision.role !== AdminRoles.Approver ? (
                  <div className="flex-row justify-center gap-5 cursor-pointer">
                    <CustomAlert
                      onPressDelete={() => {
                        console.log(index.api.getRow(index.row.id));
                        controller.deleteApartment(
                          index.api.getRow(index.row.id)?.subDivision?._id
                        );
                      }}
                    />
                  </div>
                ) : (
                  <Delete color={"disabled"} />
                );
              },
            },
            
            {
              field: "Qr Code",
              headerName: "QR Code",
              width: 80,
              headerAlign: "center",
              align: "center",
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    width={"70px"}
                    onClick={() => {
                      this.props.controller.onQrButtonPressed(
                        index.api.getRow(index.row.id)
                      );
                    }}
                    iconName={<QrCode />}
                  />
                );
              },
            },
            // {
            //   field: "setting",
            //   headerName: "Settings",
            //   width: 80,
            //   renderCell: (index) => {
            //     return index.api.getRow(index.row.id).role !=
            //       AdminRoles.Viewer &&
            //       index.api.getRow(index.row.id).role != AdminRoles.Approver ? (
            //       <ButtonWithIcon
            //         width={"60px"}
            //         buttonName={""}
            //         background={"#fa454"}
            //         onClick={() => {
            //           controller.props.navigate(
            //             `/dashboard/subdivision/settings/${
            //               index.api.getRow(index.row.id)?.subDivision?._id
            //             }`
            //           );
            //         }}
            //         iconName={<IoSettingsSharp size={25} />}
            //       />
            //     ) : (
            //       <ButtonWithIcon
            //         buttonName={""}
            //         background={"lightgray"}
            //         width={"60px"}
            //         iconName={<IoMdLock size={25} />}
            //       />
            //     );
            //   },
            // },
            // { field: 'manage', headerName: 'Manage Admins', width: 200, renderCell: (index) => { return <ButtonWithIcon buttonName={"Manage Admin"} onClick={() => { controller.props.navigate(`/dashboard/subdivision/assign-admin/${index.api.getRow(index.row.id).aparmentId}`) }} iconName={<Edit />} /> } },
            {
              field: "subdivision",
              align: "right",
              headerAlign: "left",
              headerName: false
                ? "Subdivision"
                : currentBuildingType?.FlatName
                ? currentBuildingType.FlatName
                : "",
              width: 200,
              renderCell: (index) => {
                return (
                  <ButtonWithIcon
                    onClick={() => {
                      const {
                        subdivisionTreeNavigaitonStack,
                        setSubdivisionTreeNavigaitonStack,
                      }: any = this.props.controller.context;
                      setSubdivisionTreeNavigaitonStack([
                        ...subdivisionTreeNavigaitonStack,
                        {
                          name: index.api.getRow(index.row.id).name,
                          id: index.api.getRow(index.row.id).aparmentId,
                        },
                      ]);
                      if (this.props.controller.props.isShowDropdown) {
                        controller.props.navigate(
                          `/dashboard/subdivisions/${
                            index.api.getRow(index.row.id).aparmentId
                          }`
                        );
                      } else {
                        controller.props.navigate(
                          `/dashboard/subdivision/${
                            index.api.getRow(index.row.id).aparmentId
                          }`
                        );
                      }
                    }}
                    iconName={<BsBuildingGear size={24} />}
                    width={"70px"}
                  />
                );
              },  
            },
            
          ];
    return (
      <div className="p-3 lg:px-10 lg:py-5 flex-col items-center justify-center  lg:max-w-[84vw] w-[100vw] relative">
        <div className="flex gap-2 px-4 mb-5">
          {this.props.controller.props.isShowDropdown
            ? subdivisionTreeNavigaitonStack.length > 0 &&
              subdivisionTreeNavigaitonStack.map(
                (item: { name: string; id: string }, index: number) =>
                  index != 0 && (
                    <p
                      className={`text-xl cursor-pointer font-semibold`}
                      style={{ color: currentColor }}
                      onClick={() => {
                        this.props.controller.handleNaivgationStackClick(item);
                      }}
                    >
                      {item.name != "Home" ||
                        (index != 0 && <ArrowForwardIosIcon />)}{" "}
                      {item.name}
                    </p>
                  )
              )
            : subdivisionTreeNavigaitonStack.length > 0 &&
              subdivisionTreeNavigaitonStack.map(
                (item: { name: string; id: string }, index: number) => (
                  <p
                    className={`text-xl cursor-pointer font-semibold`}
                    style={{ color: currentColor }}
                    onClick={() => {
                      this.props.controller.handleNaivgationStackClick(item);
                    }}
                  >
                    {item.name != "Home" && <ArrowForwardIosIcon />} {item.name}
                  </p>
                )
              )}
        </div>
        {this.props.controller.props.isShowDropdown && (
          <div className="flex gap-2 w-60 absolute z-10 right-[100px]   top-12">
            <SelectApartment
              onStatusChange={this.props.controller.onApartmentStatusChange}
              apartments={apartments}
            />
          </div>
        )}
        {/* <div className={style.toggle_buttons}> */}
        {/* {!this.props.controller.state.showFlatsOnly && (currentBuildingType.name != "Company" && <ToggleButtons
            buttonId="1"
            buttonName={currentBuildingType.buildingName ? currentBuildingType.buildingName : ""}
            onClick={() => {
              this.props.controller.onToggleButtonClicked(ScreenType.ManageSubdivision);
            }}
            isSelected={
              this.props.controller.state.screenType === ScreenType.ManageSubdivision ? true : false
            }
          />)}
          {!this.props.controller.state.showFlatsOnly && <ToggleButtons
            buttonId="3"
            buttonName={currentBuildingType.FlatName ? currentBuildingType.FlatName : ""}
            onClick={() => {
              this.props.controller.onToggleButtonClicked(ScreenType.ManageFlats);
            }}
            isSelected={
              currentBuildingType.name == "Company" ? true : this.props.controller.state.screenType === ScreenType.ManageFlats ? true : false
            }
          />} */}
        {/* </div> */}

        {true ? (
          <>
            {controller.state.isShowAddApartment && (
              <AddSubdivision
                controller={controller}
                onClose={controller.onShowAddApartment}
                onSubmit={controller.addAparment}
                buttonLoading={
                  controller.state.loading == loadingEnu.ADDBUTTONLOADING
                    ? true
                    : false
                }
                textFields={[]}
                isAddBox={true}
                nameToShow={
                  subdivisionTreeNavigaitonStack.length > 2 ||
                  currentBuildingType.name == "Company"
                    ? currentBuildingType.addFlat
                      ? currentBuildingType.addFlat
                      : ""
                    : currentBuildingType.addBulding
                    ? currentBuildingType.addBulding
                    : ""
                }
              />
            )}
            {controller.state.isShowEditApartment && (
              <AddSubdivision
                controller={controller}
                onClose={controller.onShowEditApartment}
                onSubmit={controller.editAparment}
                buttonLoading={
                  controller.state.loading == loadingEnu.EDITLOADING
                    ? true
                    : false
                }
                textFields={[]}
                isAddBox={false}
                nameToShow={
                  subdivisionTreeNavigaitonStack.length > 2 ||
                  currentBuildingType.name == "Company"
                    ? currentBuildingType.addFlat
                      ? currentBuildingType.addFlat
                      : ""
                    : currentBuildingType.addBulding
                    ? currentBuildingType.addBulding
                    : ""
                }
              />
            )}
            <div className="flex gap-10 w-60">
              {/* {admin.privileges.includes(AdminPrivileges.CanAddApartments) &&
                (!this.props.controller.state.showFlatsOnly && <ButtonWithIcon buttonName={currentBuildingType.addBulding ? currentBuildingType.addBulding : ""} iconName={<Add />} onClick={controller.onShowAddApartment} />)} */}
              {apartmentOfSubdivision.role !== AdminRoles.Viewer &&
                apartmentOfSubdivision.role !== AdminRoles.Approver && (
                  <ButtonWithIcon
                    buttonName={
                      subdivisionTreeNavigaitonStack.length > 2 ||
                      currentBuildingType.name == "Company"
                        ? currentBuildingType.addFlat
                          ? currentBuildingType.addFlat
                          : ""
                        : currentBuildingType.addBulding
                        ? currentBuildingType.addBulding
                        : ""
                    }
                    iconName={<Add />}
                    onClick={controller.onShowAddApartment}
                  />
                )}
            </div>
            {this.props.controller.state.loading == loadingEnu.PAGELOADING ? (
              <div className="mt-10 rounded-md overflow-x-scroll h-[77vh] overflow-y-scroll">
                <LinearIndeterminate />
                <StyledDataGrid
                  columns={columns}
                  hideFooterPagination={true}
                  style={{ background: "#fff", height: "90%" }}
                />
              </div>
            ) : (
              <div className="mt-10 rounded-md overflow-x-scroll h-[66vh] overflow-y-scroll">
                <StyledDataGrid
                  rows={controller.state.apartments.map((row: any, index) => ({
                    ...row,
                    id: index + 1,
                    qrCode: row.subDivision.qrCode,
                    location: row.subDivision.location,
                    aparmentId: row.subDivision._id,
                    name: row.subDivision.name,
                    phoneNumber: row.subDivision.phoneNumber,                                        
                  }))}
                  // pagination={undefined}
                  // autoPageSize={true}
                  columns={columns}
                  style={{ background: "#fff", height: "100%" }}
                  // hideFooterPagination={true}
              autoPageSize={true}
              pagination={true}
                  // checkboxSelection
                  onRowDoubleClick={(row: any) => {
                    if (
                      subdivisionTreeNavigaitonStack.length > 2 ||
                      currentBuildingType.name == "Company"
                    ) {
                    } else {
                      setSubdivisionTreeNavigaitonStack([
                        ...subdivisionTreeNavigaitonStack,
                        { name: row.row.name, id: row.row.aparmentId },
                      ]);
                      controller.props.navigate(
                        `/dashboard/subdivision/${row.row.aparmentId}`
                      );
                    }
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <FlatComponent
            subdivisionId={this.props.controller.props.apartmentId as string}
          />
        )}
        {/* {currentBuildingType.name == "Company" && <FlatComponent subdivisionId={this.props.controller.props.apartmentId as string} />} */}
      </div>
    );
  }
}
