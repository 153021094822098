import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Delete } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomAlert({
  onPressDelete,
  text,
  color,
  width,
  background,
  msg,
  loading,
}: any) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        style={{
          textTransform: "none",
          color: color ? color : "black",
          width,
          background,
        }}
      >
        {text != "Loading.." && (
          <Delete
            style={{
              color: color ? color : "black",
              paddingRight: color ? "4px" : "0px",
            }}
          />
        )}
        {text ? text : ""}
      </Button>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        style={{ borderRadius: "30px" }}
      >
        <DialogTitle>{"Delete"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {msg ?? "Are You Sure You Want To Delete This ?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              onPressDelete();
              handleClose();
            }}
          >
            {loading ? "Loading.." : "Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
