// import GlobalHandler from "../Handler/globalHandler";
import { BASE_URL } from "./BaseUrl";

export class ApiClientFetch{
    static apiClientFetch=new ApiClientFetch();
    static getInstance=()=>{
         if(!ApiClientFetch.apiClientFetch){
             ApiClientFetch.apiClientFetch=new ApiClientFetch();
         }
         return ApiClientFetch.apiClientFetch;
     }
    async get(url: string,token:string) {
        try {
            const response:any = await fetch(BASE_URL+`${url}`,{
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            });
            const res= await response.json();
            if(res.data.statusCode===403){
                // GlobalHandler.getInstance().logOutAgent();
            }
            return  res;
        } catch (error: any) {
            console.log(error)
            return false;
        }
    }
    async post(url: string,data:object,token:string) {
        try {
            const response:any = await fetch(BASE_URL+`${url}`,{
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(data),
            });
            const res= await response.json();
            return  res;
        } catch (error: any) {
            throw new Error(`GET request to ${url} failed: ${error.message}`);
        }
    }

    async put(url: string,data:object,token:string) {
        try {
            const response:any = await fetch(BASE_URL+`${url}`,{
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            body: JSON.stringify(data),
            });
            return response.json();
        } catch (error: any) {
            throw new Error(`GET request to ${url} failed: ${error.message}`);
        }
    }
    async delete(url: string,token:string) {
        try {
            const response:any = await fetch(BASE_URL+url,{
            method: "DELETE",
            headers: {
              "Content-Type": "application/json",
              Authorization: token,
            },
            // body: JSON.stringify(data),
            });
            const res= await response.json();
            return  res;
        } catch (error: any) {
            console.log(error)
            return false;
        }
    }

}