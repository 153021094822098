import React, { Component } from 'react'
import style from "./toggleButtons.module.css"

interface ToggleButtonsState {}
interface ToggleButtonsProps {
    buttonName: string;
    buttonId:string;
    isSelected: boolean;
    onClick: Function;
}
export default class ToggleButtons extends Component<ToggleButtonsProps,ToggleButtonsState> {
    constructor(props:ToggleButtonsProps){
        super(props);
        this.state = {}
    }
  render() {
    const {buttonId,buttonName,onClick,isSelected}=this.props;
    return (
      <div className={isSelected ? style.toggle_button_selected:style.toggle_button} onClick={()=>{onClick(buttonId)}}>
        <p className='font-semibold text-lg'>{buttonName}</p>
      </div>
    )
  }
}
