import ApiClient from "../../../Api/apiClient"

const addAdminApi=async (admin:{phoneNo:string,name:string,country:string,email:string,address:string,username:string,password:string,createdBy:string,privileges:Array<string>},token:string)=>{
    console.log("apii called addAdminApi")
     return await ApiClient.getInstance().post("/admin/addAdmin",admin,token)
     
}
const getAdminsApi=async(token:string)=>{
   return await ApiClient.getInstance().get("/admin/admins",token); 
}
const getAdminsApiByApartment=async(token:string,apartmentId:string)=>{
   return await ApiClient.getInstance().get(`/apartments/freeAdminsByApartmentId/${apartmentId}`,token); 
}
const getAdminByIdApi=async(adminId:string,token:string)=>{
   return await ApiClient.getInstance().get(`/admin/adminById/${adminId}`,token); 
}
const deleteAdminByIdApi=async(id:string,token:string)=>{
    return await ApiClient.getInstance().delete(`/admin/deleteAdmin/${id}`,token)
}
export {addAdminApi,getAdminsApi,deleteAdminByIdApi,getAdminByIdApi,getAdminsApiByApartment}