import { QRCodeSVG } from "qrcode.react";
import bell from "../Assets/bell.svg";
import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { qrBaseUrl } from "../constants";
import "../Globals/qr.css";
import html2canvas from "html2canvas";

// import { getCountryCallingCode } from "libphonenumber-js";

const QR_Bell = (props: any) => {
  const qrRef: any = useRef(null);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const images = qrRef.current?.getElementsByTagName("img");
    let loadedCount = 0;
    const checkImagesLoaded = () => {
      if (images) {
        for (let img of images) {
          if (img.complete) {
            loadedCount += 1;
          } else {
            img.onload = () => {
              loadedCount += 1;
              if (loadedCount === images.length) {
                setImagesLoaded(true);
              }
            };
            img.onerror = () => {
              loadedCount += 1;
              if (loadedCount === images.length) {
                setImagesLoaded(true);
              }
            };
          }
        }
        if (loadedCount === images.length) {
          setImagesLoaded(true);
        }
      }
    };
    checkImagesLoaded();
  }, []);

  const handleCaptureClick = async () => {
    const printElement = qrRef.current;
    if (printElement && imagesLoaded) {
      const canvas = await html2canvas(printElement, { useCORS: true });
      const dataURL = canvas.toDataURL("image/png");
      // downloadjs(dataURL, "download.png", "image/png");
    }
  };

  return (
    <div
      className="qr-wrap-bell"
      style={{}}
      // ref={qrRef}
      // onClick={handleCaptureClick}
    >
      <p className="qr-id-bell">{props.id}</p>
      <img src={bell} className="qrbell" alt="bellimg" />
      <QRCodeSVG
        size={200}
        level="H"
        // imageSettings={{
        //   src: require("../Assets/doorvi_round.jpg"),
        //   height: 45,
        //   width: 45,
        //   excavate: true,
        // }}
        className="qrcode-bell"
        value={props.value}
      />
    </div>
  );
};

export default QR_Bell;
